import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../assets/icons/Logo'

const Error = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="error_part">
        <div className="error_logo" >
          <span className="logo_item active" aria-current="page"><Logo /></span>
        </div>
        <div className="error_content">
          <div className="error_text_field">
            <h2 className='error_text'>Page Not Found 🕵🏻‍♀️</h2>
            <p className='extra_text'>Oops! 😖 The requested URL was not found on this server.</p>
          </div>
          <div className="error_text_field">
            <Button className='error_btn' size="large" onClick={() => navigate(-1)}>
              Back Home
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error