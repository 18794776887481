import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Col, message, Popconfirm, Row, Spin } from 'antd';
import '../JobCard/job.css'
import { Table, Button, Modal, Input, Form } from 'antd';
import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { useDispatch, useSelector } from "react-redux";
import { getCategoryslice, addCategorySlice, deleteCategorySlice } from '../../redux/state/categorySlice';
import { PlusOutlined } from '@ant-design/icons';
import EditCategory from './editCategory';
import { toUpper } from 'lodash';
// import ColumnGroup from 'antd/lib/table/ColumnGroup';



const Category = () => {

  const dispatch = useDispatch()
  const [myCategoryData, setMyCategoryData] = useState([])
  const [status, setStatus] = useState(true)
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editRecord, setEditRecord] = useState({})
  const [page, setPage] = useState(1);

  const [myForm] = Form.useForm()

  useEffect(() => {
    dispatch(getCategoryslice())
  }, [dispatch]);

  const { categorys, isLoading: addLoader } = useSelector(state => state.categorys)


  useEffect(() => {
    if (categorys && categorys.data) {
      const dd = categorys.data.map((e) => {
        return { ...e, key: e._id }
      })
      setMyCategoryData(dd);
    }
  }, [categorys])

  useEffect(() => {
    let ele = document.querySelector('.ant-table-measure-row').remove()
  }, [])
  // Category Add module 
  const handleAddCategory = (values) => {
    try {
      values = { ...values, is_active: status }
      dispatch(addCategorySlice(values)).then((response) => {
        if (response.payload.success) {
          message.success("Category added successfully")
          dispatch(getCategoryslice())
          setAddModel(false)
          myForm.resetFields()
        } else {
          message.error(response.payload.message)
        }
      }).catch((error) => {
        message.error(error, "category add time error")
      })
    }
    catch (err) {
      message.error(err, "add category error")
    }
  }

  //  Category delete 
  const handleDeleteCategory = (id) => {
    try {
      dispatch(deleteCategorySlice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload.isdelete) {
            dispatch(getCategoryslice())
            message.success("Category deleted successfully")
          } else {
            message.error(`Can't delete this record as other items are associated with it.`)
          }
        } else {
          message.error(response.payload.message)
        }
      })
    }
    catch (error) {
      message.error(error, "error delete time")
    }
  }

  // Edit site Module 
  const handleEditShow = (record, id) => {
    setEditModel(true)
    setEditRecord(record)
  }


  // Cancel reset 
  const handleResetSite = async () => {
    try {
      myForm.resetFields()
      setAddModel(false)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record, id) => {
        return (
          <>
            {(page - 1) * 10 + (id + 1)}
            {/* {id + 1} */}
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record, id) => {
        return (
          <>
            {toUpper(record.name)}
          </>
        )
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, record) => {
        return (
          <>
            {record.description ? record.description : "-"}
          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const text = 'Are you sure to delete this Item ?';
        return (
          <>
            <span>
              <Button className="btn_edit mr_1" onClick={() => handleEditShow(record, record._id)} icon={<EditIcon />} />
              <Popconfirm placement="topRight" title={text} onConfirm={() => handleDeleteCategory(record._id)} okText="Yes" cancelText="No" >
                <Button className="btn_delete" icon={<DeleteIcon />} />
              </Popconfirm>
            </span>
          </>
        )
      }
    }
  ];

  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <div className='main_wrapper'>
          <div className="site_header">
            <label className='content_heading'><h2>Category Details</h2></label>
            <Button className='add_btn' size="large" onClick={() => setAddModel(true)}>
              <span><PlusOutlined /></span>  Add New
            </Button>
          </div>


          {/* Site Table  */}
          <Table columns={columns} dataSource={myCategoryData} bordered sticky={true} size="middle" pagination={{ onChange(current) { setPage(current); } }} className='tabel_height' />
          <Outlet />
        </div>
      </Spin>



      {/* category Edit  */}
      <Modal open={editModel} maskClosable={false} title="Edit category" onCancel={() => setEditModel(false)} width={550} className='site_title' footer={null} >
        <EditCategory setEditModel={setEditModel} form={myForm} editRecord={editRecord} addLoader={addLoader} />
      </Modal>


      {/* Category Add Discription  */}
      <Modal open={addModel} maskClosable={false} title="Add new category" onCancel={() => handleResetSite()} className='site_title' footer={null}>
        <Spin size='large' spinning={addLoader}>
          <Form onFinish={handleAddCategory} form={myForm} layout="vertical" >
            <div className='model_wrapper_main'>
              <div className='site_form'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Category name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Category name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='name'>
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Category description</span>
                      }
                      name="description"
                      rules={[
                        {
                          required: false,
                          message: "Category discription is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate !",
                        },
                      ]}
                      description='description'>
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="third_row">
                  <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>Cancel</Button>
                  <Button className='add_site_btn' size="large" htmlType='submit'>Save</Button>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default Category