import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import locationService from '../api/LocationApi'

const initialState ={
    locations:[],
    isLoading:false,
    error:null
}

export const getLocationslice = createAsyncThunk('locations/retrive',async()=>{
    try{
        const response = await locationService.getAllLocation()
        return response.data
    } catch(error){
        console.log(error)
    }
})

export const addLocationSlice = createAsyncThunk('location/create', async(data)=>{
    try{
       const response = await locationService.addLocation(data)
       return response.data
    } catch(error){
        console.log(error)
    }
})


export const deleteLocationSlice = createAsyncThunk('location/delete', async(data)=>{
    try{
       const response = await locationService.deleteLocationById(data)
      return response.data
    } catch(error){
        console.log(error)
    }
})
export const updateLocationSlice = createAsyncThunk('location/retriveByid', async(data, id) => {
    try{
        const response = await locationService.updateLocationById(data,data.id)
        return response.data
    }catch(error){
        console.log(error)
    }
})


const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers:{
        [getLocationslice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [getLocationslice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.locations = action.payload
        },
        [getLocationslice.rejected]:(state,action)=>{
            state.isLoading=false
            state.locations = [action.payload]
        },
        [addLocationSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [addLocationSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.locations = [action.payload]
        },
        [addLocationSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
        ,
        [deleteLocationSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [deleteLocationSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.locations = [action.payload]
        },
        [deleteLocationSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
        ,
        [updateLocationSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [updateLocationSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.locations = [action.payload]
        },
        [updateLocationSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
  
    }
});

export default locationSlice.reducer

