import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import employeeService from '../api/employeeApi'

const initialState ={
    employees:[],
    isLoading:false,
    error:null
}

export const getEmployeeslice = createAsyncThunk('employee/retrive',async()=>{
    try{
        const response = await employeeService.getAllEmployee()
        return response.data
    } catch(error){
        console.log(error)
    }
})

export const addEmployeeSlice = createAsyncThunk('employee/create', async(data)=>{
    try{
       const response = await employeeService.addEmployee(data)
       return response.data ? response.data : response
    } catch(error){
        console.log(error)
    }
})

export const deleteEmployeeSlice = createAsyncThunk('employee/delete', async(id)=>{
    try{
       const response = await employeeService.deleteEmployeeById(id)
      return response.data
    } catch(error){
        console.log(error)
    }
})

export const updateEmployeeSlice = createAsyncThunk('employee/retriveByid', async(data, id) => {
    try{
        const response = await employeeService.updateEmployeeById(data,data.id)
        return response.data ? response.data : response
    }catch(error){
        console.log(error)
    }
})


const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers:{
        [getEmployeeslice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [getEmployeeslice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.employees = action.payload
        },
        [getEmployeeslice.rejected]:(state,action)=>{
            state.isLoading=false
            state.employees = [action.payload]
        },
        [addEmployeeSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [addEmployeeSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.employees = [action.payload]
        },
        [addEmployeeSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.employees = [action.payload]
        },
        [deleteEmployeeSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [deleteEmployeeSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.employees = [action.payload]
        },
        [deleteEmployeeSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.employees = [action.payload]
        }
        ,
        [updateEmployeeSlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [updateEmployeeSlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.employees = [action.payload]
        },
        [updateEmployeeSlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.employees = [action.payload]
        }
  
  
    }
});

export default employeeSlice.reducer

