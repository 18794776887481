import { Button, Col, Form, Input, message, Row, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getCategoryslice, updateCategorySlice } from '../../redux/state/categorySlice';

const EditCategory = ({ editRecord, setEditModel, addLoader }) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  useEffect(() => {
    form.setFieldsValue({
      name: editRecord.name,
      description: editRecord.description,
      id: editRecord._id
    })
  }, [editRecord])


  const handleEditCategory = async (record) => {
    try {
      dispatch(updateCategorySlice(record)).then((response) => {
        if (response.payload.success) {
          dispatch(getCategoryslice())
          setEditModel(false)
          message.success("Category edited successfully")
        } else {
          message.error("Category not edited")
        }
      })
    }
    catch (err) {
      message.error(err, "Edit category error")
    }
  }


  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form onFinish={handleEditCategory} form={form} layout="vertical" >
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Category name</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Category name is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate name!",
                      },
                    ]}
                    name='name'>
                    <Input className='site_input_group' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Category description</span>
                    }
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Discription is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate !",
                      },
                    ]}
                    description='description'>
                    <Input className='site_input_group' />
                  </Form.Item>
                </Col>
              </Row>

              <div className="second_row">
                <Row>
                  <Col span={12} className='user_field'>
                    <Form.Item
                      name='id' >
                      <Input type={'hidden'} className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => setEditModel(false)}>Cancel</Button>
                <Button className='update_site_btn' size="large" htmlType='submit'>Update</Button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default EditCategory