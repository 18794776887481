import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import reportService from '../api/reportsApi'

const initialState = {
    reports: [],
    jobcardDatas: [],
    isLoading: false,
    error: null
}

export const getreportJobslice = createAsyncThunk('lowproducts/retrive', async ({ status, startDate, endDate }) => {
    try {
        const response = await reportService.getJobcardReport({ status, startDate, endDate })
        return response?.data
    } catch (error) {
        console.log(error)
    }
})
export const getSinglejobcardSlice = createAsyncThunk('singletemplate/retrive', async (jobcardId) => {
    try {
        const response = await reportService.getsinglejobcardById(jobcardId)
        return response?.data
    } catch (error) {
        console.log(error)
    }
})

const reportJobSlice = createSlice({
    name: 'report-job',
    initialState,
    reducers: {},
    extraReducers: {
        [getreportJobslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getreportJobslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.reports = [action.payload]
        },
        [getreportJobslice.rejected]: (state, action) => {
            state.isLoading = false
            state.reports = [action.payload]
        },
        [getSinglejobcardSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getSinglejobcardSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.jobcardDatas = [action.payload]
        },
        [getSinglejobcardSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.jobcardDatas = [action.payload]
        }
    }
});

export default reportJobSlice.reducer