import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginForm from '../components/userAuth/LoginForm';
import MainLayout from '../components/common/layout';
import Site from '../components/sites';
import Location from '../components/location';
import DashbordContent from '../components/dashboard';
import Category from '../components/Catagory';
import Products from '../components/Products';
import Jobcard from '../components/JobCard';
import Employee from '../components/Employee';
import Reports from '../components/Report';
import Error from '../components/common/layout/error';
import Protected from '../components/userAuth/protectedRoute';
import EditJob from '../components/JobCard/EditJob';
import Template from '../components/Template';


const MainRouter = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<LoginForm />} />
                    <Route path={'/'} element={<Protected Component={MainLayout} />} >
                        <Route path={'/'} element={<Protected Component={DashbordContent} />} />
                        <Route path={"/site"} element={<Protected Component={Site} />} />
                        <Route path={'/location'} element={<Protected Component={Location} />} />
                        <Route path={'/Category'} element={<Protected Component={Category} />} />
                        <Route path={'/product'} element={<Protected Component={Products} />} />
                        <Route path={'/jobcard'} element={<Protected Component={Jobcard} />} />
                        {/* <Route path={'/jobcard/add-job'} element={<Protected Component={AddJob} />} /> */}
                        <Route path={'/jobcard/editjob/:id'} element={<Protected Component={EditJob} />} />

                        <Route path={'/employee'} element={<Protected Component={Employee} />} />
                        <Route path={'/template'} element={<Protected Component={Template} />} />

                        <Route path={'/report'} element={<Protected Component={Reports} />} />
                    </Route>
                    <Route path='/*' element={<Error />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default MainRouter