import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../../assets/icons/EditIcon";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import "./job.css";
import {
  Table,
  Button,
  Spin,
  message,
  Popconfirm,
  Modal,
  Row,
  Col,
  Input,
  Form,
  Checkbox,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  getJobslice,
  deleteJobSlice,
  getJobitemslice,
} from "../../redux/state/jobSlice";
import AddJob from "./AddJob";
import EditJob from "./EditJob";
import { CheckMark } from "../../assets/icons/CheckMark";
import Highlighter from "react-highlight-words";
import moment from "moment";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";

const Jobcard = () => {
  const [myJobData, setMyJobData] = useState([]);
  const [chassisName, setChassisName] = useState([]);
  const [completeImage, setcompleteImage] = useState([]);
  const [view, setView] = useState(false);
  const [productItem, setProductItem] = useState([]);
  const [addModal, setAddModel] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editRecord1, setEditRecord1] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [size, setSize] = useState({ width: 800, height: 400 });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [toggle, setToggle] = useState(true);
  const draggleRef = useRef(null);

  //resize modal
  const handleResize = (event, { size }) => {
    setSize(size);
  };
  //dragable modal
  const { clientWidth, clientHeight } = window.document.documentElement;

  const onStart = (_event, uiData) => {
    console.log("clientHeight,clientWidth :>> ", clientHeight, clientWidth);
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x + 10),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y + 20),
    });
  };

  const fullWidthAdjustable = async () => {
    if (toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      dragableElement.classList.add("topleftmodal");
      setSize({ width: clientWidth - 100, height: clientHeight - 130 });
    }
    setToggle(false);
  };
  const minWidthAdjustable = async () => {
    setToggle(true);
    if (!toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      console.log("dragableElement :>> ", dragableElement);
      dragableElement.classList.remove("topleftmodal");
      setSize({ width: 800, height: 400 });
    }
  };

  // TABLE SEARCHING START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    console.log("first");
    setSearchText("");
    clearFilters();
    close();
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              confirm &&
              handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // TABLE SEARCHING END

  useEffect(() => {
    dispatch(getJobslice());
  }, [dispatch]);

  const {
    jobs,
    productItems,
    isLoading: addLoader,
  } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (jobs && jobs.data) {
      const dd = jobs.data.map((e) => {
        return { ...e, key: e._id };
      });
      setMyJobData(dd);
    }
  }, [jobs]);

  //  job delete
  const handleDeleteJobcard = (id) => {
    try {
      dispatch(deleteJobSlice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload.isdelete) {
            dispatch(getJobslice());
            message.success("Jobcard deleted successfully");
          } else {
            message.error(
              `Can't delete this record as other items are associated with it.`
            );
          }
        } else {
          message.error(response.payload.message);
        }
      });
    } catch (error) {
      message.error(error, "error delete time");
    }
  };

  const handleEditShow = (record, id) => {
    setEditRecord1(record);
    setEditModal(true);
  };

  // View Jobcard Items.

  const handleCancel = () => {
    setView(false);
    setProductItem([]);
    setSize({ width: 800, height: 400 });
    setToggle("true");
  };

  const ViewJobCardItem = (id) => {
    try {
      setView(true);
      dispatch(getJobitemslice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload && response.payload.data) {
            const productItems = response.payload.data.map((e) => {
              return { ...e, key: e._id };
            });
            setChassisName(response.payload);
            setcompleteImage(response.payload.data[0].jobcardId.image);
            // console.log(response.payload.data[0].jobcardId.image)

            setProductItem(productItems);
          } else {
            message.error(response.payload.message);
          }
        }
      });
    } catch (error) {
      console.error(error, "get JobCardItem");
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (_, record, id) => {
        return (
          <>
            {/* {id + 1} */}
            {(page - 1) * 10 + (id + 1)}
          </>
        );
      },
    },
    {
      title: "Chassis Number",
      dataIndex: "jobCardNo",
      ...getColumnSearchProps("jobCardNo", "Chassis Number"),
      sorter: (a, b) => a.jobCardNo.localeCompare(b.jobCardNo),
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <>
            {record.status == "completed" ? (
              <span className="completed_task">Completed</span>
            ) : (
              <span>
                {record.status === "inprocess" ? (
                  <span className="inprocess_task">In progress</span>
                ) : (
                  <span className="pending_task">Pending</span>
                )}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => {
        return (
          <>
            {record.status == "completed" ? (
              <span>
                {moment(record.end_date).format("DD-MM-YYYY hh:mm:A")}
              </span>
            ) : (
              <span>
                {record.status === "inprocess" ? (
                  <span>
                    {moment(record.start_date).format("DD-MM-YYYY hh:mm:A")}
                  </span>
                ) : (
                  <span>
                    {moment(record.create_date).format("DD-MM-YYYY hh:mm:A")}
                  </span>
                )}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Assign By",
      dataIndex: "assignby",
      render: (_, record) => {
        return (
          <>
            {record.status == "completed" ? (
              <span>{record.staff.length ? record.staff[0].name : "-"}</span>
            ) : (
              <span>
                {record.status === "inprocess" ? (
                  <span>
                    {record.staff.length ? record.staff[0].name : "-"}
                  </span>
                ) : (
                  <span>{"-"}</span>
                )}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const text = "Are you sure to delete this Item ?";
        return (
          <>
            {record.status == "inprocess" || record.status == "completed" ? (
              <Button
                className="btn_view mr_1"
                onClick={() => ViewJobCardItem(record._id)}
                icon={<EyeOutlined />}
              />
            ) : (
              <span>
                <Button
                  className="btn_view mr_1"
                  onClick={() => ViewJobCardItem(record._id)}
                  icon={<EyeOutlined />}
                />

                <Button
                  className="btn_edit mr_1"
                  onClick={() => handleEditShow(record, record._id)}
                  icon={<EditIcon />}
                />

                <Popconfirm
                  placement="topRight"
                  title={text}
                  onConfirm={() => handleDeleteJobcard(record._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="btn_delete" icon={<DeleteIcon />} />
                </Popconfirm>
              </span>
            )}
          </>
        );
      },
    },
  ];

  const Productcolumns = [
    {
      title: "Product name",
      dataIndex: "_id",
      render: (_, record) => {
        return <>{record?.productId?.name}</>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "age",
      render: (_, record) => {
        return <>{record.qty ? record.qty : "1"}</>;
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "address",
      render: (_, record) => {
        return <>{record.comment ? record.comment : "-"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      render: (_, record) => {
        return (
          <>
            {/* <Checkbox checked= {record.outItemStatus ? record.outItemStatus : false} ></Checkbox> */}
            {record.outItemStatus == true ? <CheckMark /> : ""}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin size="large" spinning={addLoader}>
        <div className="main_wrapper">
          <div className="site_header">
            <label className="content_heading">
              <h2>Job Details</h2>
            </label>
            <Button
              className="add_btn"
              size="large"
              onClick={() => setAddModel(true)}
            >
              {/* <Button className='add_btn' size="large" onClick={() => navigate("/jobcard/add-job", { replace: true })} > */}
              <span>
                <PlusOutlined />
              </span>{" "}
              Add New
            </Button>
          </div>

          {/* jobcard Table  */}
          <Table
            columns={columns}
            bordered
            dataSource={myJobData}
            sticky={true}
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            size="middle"
            className="tabel_height main_table"
          />
        </div>
      </Spin>

      <Modal
        title="Edit job card"
        footer={null}
        open={editModal}
        width={800}
        onCancel={() => setEditModal(false)}
      >
        <EditJob setEditModal={setEditModal} editRecord1={editRecord1} />
      </Modal>

      {/* Add modal  */}
      <Modal
        title={
          <div className="title_div">
            <div
              style={{
                width: "96%",
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              Add new job card
            </div>
            {toggle ? (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={fullWidthAdjustable}
              >
                <FullscreenOutlined />
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={minWidthAdjustable}
              >
                <FullscreenExitOutlined />
              </div>
            )}
          </div>
        }
        footer={null}
        open={addModal}
        width={size.width + 50}
        onCancel={() => {
          setAddModel(false);
          setSize({ width: 800, height: 400 });
          setToggle("true");
        }}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        className="job_product_data"
      >
        <ResizableBox
          width={size.width}
          height={size.height}
          onResize={handleResize}
          minConstraints={[900, 400]}
          maxConstraints={[clientWidth - 100, clientHeight - 50]}
        >
          <AddJob setAddModel={setAddModel} addModal={addModal} />
        </ResizableBox>
      </Modal>

      {/* View popup modal */}
      <Modal
        width={size.width + 50}
        title={
          <div className="title_div">
            <div
              style={{
                width: "96%",
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              View job details
            </div>
            {toggle ? (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={fullWidthAdjustable}
              >
                <FullscreenOutlined />
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={minWidthAdjustable}
              >
                <FullscreenExitOutlined />
              </div>
            )}
          </div>
        }
        footer={null}
        open={view}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        className="job_product_data"
      >
        {" "}
        <ResizableBox
          width={size.width}
          height={size.height}
          onResize={handleResize}
          minConstraints={[900, 400]}
          maxConstraints={[clientWidth - 100, clientHeight - 50]}
        >
          <div className="model_lable_1 ">
            <Row gutter={12}>
              <Col span={20} className="product_lable_view">
                <Form.Item
                  className="form_control"
                  label={
                    <span className="product_lable_1">Chassis number</span>
                  }
                >
                  <p className="site_input_group_1">
                    {chassisName.chassisname}
                  </p>
                </Form.Item>
              </Col>
              {/* {Object.keys(chassisName?.data[0]?.jobcardId).includes("images")} */}
              <Col span={4}>
                <div className="pro_list">
                  <p className="site_input_group_1">
                    {completeImage == undefined || completeImage == "" ? (
                      ""
                    ) : (
                      <img
                        src={completeImage}
                        width="85px"
                        height="75px"
                        alt=""
                      />
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <Table
            dataSource={productItem}
            bordered
            columns={Productcolumns}
            sticky
            pagination={false}
            className="job_product_data"
          />
        </ResizableBox>
      </Modal>
    </>
  );
};

export default Jobcard;
