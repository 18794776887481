import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import lowProductService from '../api/lowProduct'

const initialState ={
    lowProducts:[],
    isLoading:false,
    error:null
}

export const getLowProductslice = createAsyncThunk('lowproducts/retrive',async()=>{
    try{
        const response = await lowProductService.getLowproduct()
        return response.data
    } catch(error){
        console.log(error)
    }
})


const lowproductSlice = createSlice({
    name: 'low-product',
    initialState,
    reducers: {},
    extraReducers:{
        [getLowProductslice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [getLowProductslice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.lowProducts = [action.payload]
        },
        [getLowProductslice.rejected]:(state,action)=>{
            state.isLoading=false
            state.lowProducts = [action.payload]
        }
    }
});

export default lowproductSlice.reducer