import { api } from './index'
import setAuthToken from "./headers"

const getAllSite = async () => {
    return await api.get("api/v1/warehouses", { headers: setAuthToken() })
}

// Add site Api
const addSite = async (data) => {
    return await api.post("api/v1/warehouses", data, { headers: setAuthToken() })
}

const deleteSiteById = async (id) => {
    return await api.delete(`/api/v1/warehouses/${id}`)
}
const updateSiteById = async (data, id) => {
    return await api.put(`/api/v1/warehouses/${id}`, data, { headers: setAuthToken() })
}



const siteService = {
    getAllSite,
    addSite,
    deleteSiteById,
    updateSiteById


}

export default siteService;