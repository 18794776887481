import './icons.css'

export const EditIcon = () => {

    return (
        <>
            <span role="img" aria-label="eye" className="anticon anticon-eye">
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.312 14.5124C23.1451 14.5124 22.985 14.5787 22.8669 14.6968C22.7489 14.8148 22.6826 14.9749 22.6826 15.1418V20.731C22.6819 21.2312 22.4828 21.7108 22.1291 22.0645C21.7754 22.4182 21.2958 22.6173 20.7956 22.618H3.14455C2.8964 22.6176 2.65074 22.5684 2.42161 22.4731C2.19248 22.3778 1.98436 22.2383 1.80914 22.0626C1.63392 21.8869 1.49502 21.6784 1.40038 21.449C1.30574 21.2196 1.25721 20.9738 1.25755 20.7256V4.33352C1.25826 3.83327 1.45729 3.35372 1.81102 2.99999C2.16475 2.64626 2.64431 2.44723 3.14455 2.44652H8.73369C8.89544 2.43908 9.04811 2.36958 9.15995 2.25248C9.27179 2.13537 9.33419 1.97967 9.33419 1.81774C9.33419 1.65581 9.27179 1.50011 9.15995 1.38301C9.04811 1.26591 8.89544 1.19641 8.73369 1.18896H3.14455C2.31089 1.19002 1.51167 1.52166 0.922185 2.11115C0.332696 2.70064 0.00105639 3.49986 0 4.33352L0 20.7256C0.00105639 21.5593 0.332696 22.3585 0.922185 22.948C1.51167 23.5375 2.31089 23.8691 3.14455 23.8702H20.7956C21.6292 23.8691 22.4284 23.5375 23.0179 22.948C23.6074 22.3585 23.9391 21.5593 23.9401 20.7256V15.1365C23.9387 14.9707 23.872 14.8122 23.7544 14.6953C23.6368 14.5785 23.4778 14.5127 23.312 14.5124V14.5124Z" fill="#49B951" />
                    <path d="M9.83545 11.2012L19.0176 2.01904L21.9798 4.98128L12.7977 14.1634L9.83545 11.2012Z" fill="#49B951" />
                    <path d="M8.33716 15.6621L11.6068 14.7585L9.2394 12.3911L8.33716 15.6621Z" fill="#49B951" />
                    <path d="M22.8024 0.459755C22.5073 0.165341 22.1074 0 21.6906 0C21.2737 0 20.8738 0.165341 20.5787 0.459755L19.9133 1.12513L22.8756 4.08737L23.541 3.422C23.8354 3.12687 24.0007 2.72702 24.0007 2.31016C24.0007 1.89329 23.8354 1.49344 23.541 1.19832L22.8024 0.459755Z" fill="#49B951" />
                </svg></span>
        </>
    )
}