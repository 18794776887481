import { api } from './index'
import setAuthToken from "./headers"
import errorFunction from './error'

const getAllProduct = async () => {
    try {
        return await api.get("api/v1/product", { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}
// Add Product Api
const addProduct = async (data) => {
    try {
        return await api.post("api/v1/product", data, { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
        return errorFunction(err.response.status, err)

    }
}
const deleteProductById = async (id) => {
    return await api.delete(`api/v1/product/${id}`, { headers: setAuthToken() })
}
const updateProductById = async (data, id) => {
    try {
        return await api.put(`api/v1/product/${id}`, data, { headers: setAuthToken() })
    } catch (err) {
        if (err.response.data) {
            return err.response;
        } else { return {} }
    }
}




const productService = {
    getAllProduct,
    addProduct,
    deleteProductById,
    updateProductById

}

export default productService;