import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import categoryService from '../api/CategoryApi'

const initialState ={
    categorys:[],
    isLoading:false,
    error:null
}

export const getCategoryslice = createAsyncThunk('category/retrive',async()=>{
    try{
        const response = await categoryService.getAllCategory()
        return response.data
    } catch(error){
        console.log(error)
    }
})

export const addCategorySlice = createAsyncThunk('category/create', async(data)=>{
    try{
       const response = await categoryService.addCategory(data)
       return response.data ?  response.data : response
    } catch(error){
        console.log(error)
    }
})

export const deleteCategorySlice = createAsyncThunk('category/delete', async(id)=>{
    try{
       const response = await categoryService.deleteCategoryById(id)
      return response.data
    } catch(error){
        console.log(error)
    }
})

export const updateCategorySlice = createAsyncThunk('site/retriveByid', async(data, id) => {
    try{
        const response = await categoryService.updateCategoryById(data,data.id)
        return response.data ?  response.data : response
    }catch(error){
        console.log(error)
    }
})


const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers:{
        [getCategoryslice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [getCategoryslice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.categorys = action.payload
        },
        [getCategoryslice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        },   
        [addCategorySlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [addCategorySlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.categorys = [action.payload]
        },
        [addCategorySlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
        ,   
        [deleteCategorySlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [deleteCategorySlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.categorys = [action.payload]
        },
        [deleteCategorySlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
        ,   
        [updateCategorySlice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [updateCategorySlice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.categorys = [action.payload]
        },
        [updateCategorySlice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }
  
    }
});

export default categorySlice.reducer

