import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const Protected = (props) => {
    const { Component, } = props;
    const navigate = useNavigate();
    useEffect(() => {
        let login = localStorage.getItem("admin");
        if (!login) {
            navigate("/login", { replace: true });
        }
    }, []);
    return (
        <Component />
    )
}
export default Protected
