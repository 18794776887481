import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import jobService from '../api/jobcardApi'

const initialState = {
    jobs: [],
    productItems: [],
    isLoading: false,
    error: null
}

export const getJobslice = createAsyncThunk('jobs/retrive', async () => {
    try {
        const response = await jobService.getAllJob()
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const addJobslice = createAsyncThunk('jobs/add', async (data) => {
    try {
        const response = await jobService.addJobcard(data)
        return response.data ? response.data : response
    } catch (error) {
        console.log(error)
    }
})

export const deleteJobSlice = createAsyncThunk('jobs/delete', async (id) => {
    try {
        const response = await jobService.deleteJobcadById(id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})


export const getJobitemslice = createAsyncThunk('jobitem/retrive', async (id) => {
    try {
        const response = await jobService.getProductItemDetails(id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const updateJobSlice = createAsyncThunk('job/retriveByid', async (data) => {
    try {
        const response = await jobService.updateJobById(data, data.id)
        return response.data ? response.data : response
    } catch (error) {
        console.log(error)
    }
})

export const getSingleJoblice = createAsyncThunk('singlejob/retrive', async (id) => {
    try {
        const response = await jobService.getsingleJobById(id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {},
    extraReducers: {
        [getJobslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getJobslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.jobs = action.payload
        },
        [getJobslice.rejected]: (state, action) => {
            state.isLoading = false
            state.jobs = [action.payload]
        },
        [getJobitemslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getJobitemslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.productItems = action.payload
        },
        [getJobitemslice.rejected]: (state, action) => {
            state.isLoading = false
            state.productItems = [action.payload]
        },
        [deleteJobSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteJobSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.jobs = [action.payload]
        },
        [deleteJobSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.jobs = [action.payload]
        },
        [addJobslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [addJobslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.jobs = action.payload
        },
        [addJobslice.rejected]: (state, action) => {
            state.isLoading = false
            state.jobs = [action.payload]
        },
        [updateJobSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateJobSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.jobs = action.payload
        },
        [updateJobSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.jobs = [action.payload]
        }
        ,
        [getSingleJoblice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getSingleJoblice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.productItems = action.payload
        },
        [getSingleJoblice.rejected]: (state, action) => {
            state.isLoading = false
            state.productItems = [action.payload]
        }

    }
});

export default jobSlice.reducer

