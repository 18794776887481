import { api } from './index'
import setAuthToken from "./headers"

const getAllLocation = async() =>{
    try {
        return await api.get("api/v1/location",{headers: setAuthToken()})
    } catch(err){
        console.log(err)
    }
}
// Add Location Api
const addLocation = async(data) =>{
    return await api.post("api/v1/location", data, {headers: setAuthToken()})
}

const deleteLocationById = async(id)=>{
    return await api.delete(`api/v1/location/${id}`,{headers: setAuthToken()})
}
const updateLocationById = async(data,id)=>{
    return await api.put(`api/v1/location/${id}`, data,  {headers: setAuthToken()}) 
}

const locationService = {
    getAllLocation,
    addLocation,
    deleteLocationById,
    updateLocationById

}

export default locationService;