import { Button, Col, Form, message, Row, Spin } from 'antd';
import Input from '../common/Index'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getEmployeeslice, updateEmployeeSlice } from '../../redux/state/employeeSlice';

const Editemployee = ({ editRecord, setEditModel, addLoader }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    useEffect(() => {
        form.setFieldsValue({
            name: editRecord.name,
            username: editRecord.username,
            address: editRecord.address,
            id: editRecord._id
        })
    }, [editRecord])


    const handleEditEmployee = async (record) => {
        try {
            dispatch(updateEmployeeSlice(record)).then((response) => {
                if (response.payload.success) {
                    dispatch(getEmployeeslice())
                    setEditModel(false)
                    message.success("Employee edited successfully")
                } else {
                    message.error("Employee not edited")
                }
            })
        }
        catch (err) {
            message.error(err, "Edit employee error")
        }
    }




    return (
        <>
            <Spin size='large' spinning={addLoader}>
                <Form layout="vertical" form={form} onFinish={handleEditEmployee}>
                    <div className='model_wrapper_main'>
                        <div className='site_form'>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className='form_control'
                                        label={
                                            <span className="product_lable">Edit name</span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Employee name is required",
                                            },
                                            {
                                                type: "text",
                                                message: "Please enter a validate name!",
                                            },
                                        ]}
                                        name="name"
                                    >
                                        <Input maxLength={80} className='site_input_group' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className='form_control'
                                        label={
                                            <span className="product_lable">User name</span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "User name is required",
                                            },
                                            {
                                                type: "text",
                                                message: "Please enter a validate !",
                                            },
                                        ]}
                                        name="username"
                                    >
                                        <Input disabled className='site_input_group' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="second_row">
                                <Row>
                                    <Col span={12} className='user_field'>
                                        <Form.Item
                                            name='id' >
                                            <Input type={'hidden'} className='site_input_group' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="third_row">
                                <Button className='Cancel_btn' size="large" onClick={() => setEditModel(false)}>
                                    Cancel
                                </Button>
                                <Button className='update_site_btn' size="large" htmlType='submit'>
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Spin>
        </>
    )
}

export default Editemployee