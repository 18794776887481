import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import templateService from '../api/templateApi'

const initialState = {
    templates: [],
    templatesItems: [],
    isLoading: false,
    error: null
}

export const getTemplateslice = createAsyncThunk('templates/retrive', async () => {
    try {
        const response = await templateService.getTemplateData()
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const getTemplateByIdslice = createAsyncThunk('templates/retrive', async (id) => {
    try {
        const response = await templateService.getTemplateDataById(id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const addTemplateslice = createAsyncThunk('jobs/add', async (data) => {
    try {
        const response = await templateService.addTemplateData(data)
        return response.data ? response.data : response
    } catch (error) {
        console.log(error)
    }
})
export const updateTemplateSlice = createAsyncThunk('template/retriveByid', async (data) => {
    try {
        const response = await templateService.updateTemplateById(data, data.id)
        return response.data ? response.data : response
    } catch (error) {
        console.log(error)
    }
})
export const getSingleTemplateSlice = createAsyncThunk('singletemplate/retrive', async (id) => {
    try {
        const response = await templateService.getsingleTemplateById(id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const deleteTemplateSlice = createAsyncThunk('template/delete', async (data) => {
    try {
        const response = await templateService.deleteTemplateById(data)
        return response.data
    } catch (error) {
        console.log(error)
    }
})



const templateSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: {
        [getTemplateslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTemplateslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templates = action.payload
        },
        [getTemplateslice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
        [getTemplateByIdslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTemplateByIdslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templates = action.payload
        },
        [getTemplateByIdslice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
        ,
        [addTemplateslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [addTemplateslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
        },
        [addTemplateslice.rejected]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
            // state.error = [action.payload]
        }
        ,
        [updateTemplateSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateTemplateSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
        },
        [updateTemplateSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
            // state.error = [action.payload]
        }
        ,
        [getSingleTemplateSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getSingleTemplateSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templatesItems = [action.payload]
        },
        [getSingleTemplateSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.templatesItems = [action.payload]
            // state.error = [action.payload]
        }
        ,
        [deleteTemplateSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteTemplateSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
        },
        [deleteTemplateSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.templates = [action.payload]
            // state.error = [action.payload]
        }
    }
});

export default templateSlice.reducer

