import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dashboardDataService from '../api/dashboardApi'

const initialState = {
    dashboard: [],
    isLoading: false,
    error: null
}

export const getDashboardSlice = createAsyncThunk('dashboard/retrive', async ({ jobStatus, month }) => {
    try {
        const response = await dashboardDataService.getDashboardData(jobStatus, month)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        [getDashboardSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getDashboardSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.dashboard = action.payload
        },
        [getDashboardSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
    }
});

export default dashboardSlice.reducer

