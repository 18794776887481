import React from 'react'
import { Col, message, Row, Spin } from 'antd';
import '../sites.css'
import { Button, Input, Form } from 'antd';
import { useEffect } from 'react';
import { getSiteslice, updateSiteSlice } from '../../../redux/state/siteSlice';
import { useDispatch } from 'react-redux';


const Editsite = ({ setEditModel, addLoader, editRecord }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  useEffect(() => {
    form.setFieldsValue({
      name: editRecord.name,
      location: editRecord.location,
      id: editRecord._id
    })
  }, [editRecord])

  const handleEditSite = async (record) => {
    try {
      dispatch(updateSiteSlice(record)).then((response) => {
        if (response.payload.success) {
          dispatch(getSiteslice())
          setEditModel(false)
          message.success("Site edited successfully")
        } else {
          message.error("Site not edited")
        }
      })
    }
    catch (err) {
      message.error(err, "Edit site error")
    }
  }




  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form form={form} onFinish={handleEditSite} layout="vertical" >
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Name</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Site name is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate name!",
                      },
                    ]}
                    name='name'>
                    <Input className='site_input_group' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Address</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Site address is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate !",
                      },
                    ]}
                    name='location' >
                    <Input className='site_input_group' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='id' >
                    <Input type={'hidden'} className='site_input_group' />
                  </Form.Item>
                </Col>
              </Row>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => setEditModel(false)}>Cancel</Button>
                <Button className='add_site_btn' size="large" htmlType='submit'>Save</Button>
              </div>
            </div>

          </div>
        </Form>
      </Spin>
    </>
  )
}

export default Editsite