import { api } from './index';
import setAuthToken from "./headers"

const getJobcardReport = async ({ status, startDate, endDate }) => {
    try {
        return await api.get(`api/v1/${status}/jobcard_report/${startDate}/${endDate}`, { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}
const getsinglejobcardById = async (jobcardId) => {
    return await api.get(`api/v1/jobcard_costing/${jobcardId}`, { headers: setAuthToken() })
}




const reportService = {
    getJobcardReport,
    getsinglejobcardById

}

export default reportService;