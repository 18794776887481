import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import MainRouter from "./router";
import "antd/dist/antd.min.css";
import { store } from './redux/state'
import { Provider } from 'react-redux'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <Provider store={store}>
            <MainRouter />
      </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
