import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dropdownService from '../api/dropdownApi'

const initialState = {
    sitedropdowns: [],
    locationdropdowns: [],
    categorydropdowns: [],
    employeedropdowns: [],
    productdropdowns: [],
    templatedropdowns: [],
    isLoading: false,
    error: null
}

export const getSiteDropSlice = createAsyncThunk('dropdown/retrive', async () => {
    try {
        const response = await dropdownService.getSiteDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getLocationDropSlice = createAsyncThunk('locationdropdown/retrive', async () => {
    try {
        const response = await dropdownService.getLocationDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})


export const getCategoryDropSlice = createAsyncThunk('categorydropdown/retrive', async () => {
    try {
        const response = await dropdownService.getCategoryDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getEmployeeDropSlice = createAsyncThunk('employeedropdown/retrive', async () => {
    try {
        const response = await dropdownService.getEmployeeDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const getProductDropSlice = createAsyncThunk('productdropdown/retrive', async () => {
    try {
        const response = await dropdownService.getProductDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const getTemplateDropSlice = createAsyncThunk('template/retrive', async () => {
    try {
        const response = await dropdownService.getTemplateDropdown()
        return response.data
    } catch (error) {
        console.log(error)
    }
})


const dropdownSlice = createSlice({
    name: 'drop',
    initialState,
    reducers: {},
    extraReducers: {
        [getSiteDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getSiteDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.sitedropdowns = action.payload
        },
        [getSiteDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
        [getLocationDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getLocationDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.locationdropdowns = action.payload
        },
        [getLocationDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
        [getCategoryDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getCategoryDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.categorydropdowns = action.payload
        },
        [getCategoryDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
        ,
        [getEmployeeDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getEmployeeDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.employeedropdowns = action.payload
        },
        [getEmployeeDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
        [getProductDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getProductDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.productdropdowns = action.payload
        },
        [getProductDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
        ,
        [getTemplateDropSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTemplateDropSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.templatedropdowns = action.payload
        },
        [getTemplateDropSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }

    }
});

export default dropdownSlice.reducer

