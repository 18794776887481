import { Layout } from 'antd';
import React, { } from 'react';
import Sidebar from './Sidebar';
import HeaderComp from "./Header";
import Contents from './Content';
import './index.css'



const MainLayout = () => {

    return (
        <Layout>
                <HeaderComp />
            <Layout className="site-layout">
            <Sidebar />
                
                <Contents />
            </Layout>
    
        </Layout>

    );
};

export default MainLayout;