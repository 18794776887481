import { api } from './index';
import setAuthToken from "./headers"

const getDashboardData = async (status, month1) => {
    try {
        return await api.get(`api/v1/${status}/dashbord/${month1}`, { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}


const dashboardDataService = {
    getDashboardData,

}

export default dashboardDataService;