export const UploadIcon = () => {
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.35">
                    <path d="M31.4845 16.9532C30.3905 11.5627 25.703 7.5 20 7.5C15.4689 7.5 11.5627 10.078 9.60953 13.8282C4.92203 14.297 1.25 18.2811 1.25 23.125C1.25 28.2809 5.46867 32.5 10.625 32.5H30.9375C35.2344 32.5 38.75 28.9844 38.75 24.6875C38.75 20.547 35.5472 17.1874 31.4845 16.9532ZM22.5 21.5625V27.5H17.5V21.5625H12.1875L20 13.75L27.8125 21.5625H22.5Z" fill="black" />
                </g>
            </svg>

        </>
    )
}