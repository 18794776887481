import { api } from './index'
import errorFunction from './error'

const LoginData = async (data) => {
    try {
        return await api.post("api/v1/login", data)
    } catch (err) {
        console.error(err.response, "new data")
        return errorFunction(err.response.status, err)

    }
}

const loginService = {
    LoginData
}

export default loginService;