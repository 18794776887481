import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Col, message, Popconfirm, Row, Select, Spin } from 'antd';
import '../sites/sites.css'
import { Table, Button, Modal, Input, Form } from 'antd';
import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { useDispatch, useSelector } from "react-redux";
import { getLocationslice, addLocationSlice, deleteLocationSlice } from '../../redux/state/locationSlice';
import { getSiteDropSlice } from '../../redux/state/dropdownSlice';
import { PlusOutlined } from '@ant-design/icons';
import EditLocation from './editLocation';
import { toUpper } from 'lodash';

const { Option } = Select;


const Location = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [myLocationData, setMyLocationData] = useState([])
  const [siteData, setSiteData] = useState([])
  const [editModel, setEditModel] = useState(false);
  const [editRecord, setEditRecord] = useState({})
  const [page, setPage] = useState(1);

  const [myForm] = Form.useForm();

  useEffect(() => {
    dispatch(getLocationslice())
    dispatch(getSiteDropSlice())
  }, [dispatch]);

  const { sitedropdowns } = useSelector(data1 => data1.sitedropdowns)
  const { locations, isLoading: addLoader } = useSelector(state => state.locations)
  const [form] = Form.useForm();

  useEffect(() => {
    if (locations && locations.data) {
      const dd = locations.data.map((e) => {
        return { ...e, key: e._id }
      })
      setMyLocationData(dd);
    }
    if (sitedropdowns && sitedropdowns.data) {
      const drpdown = sitedropdowns.data.map((e) => {
        return { ...e, key: e._id }
      })
      setSiteData(drpdown);
    }
  }, [locations, sitedropdowns])


  // save Add Location
  const handleAddLocation = (values) => {
    try {
      values = { ...values, is_active: true }
      dispatch(addLocationSlice(values))
        .then((response) => {
          if (response.payload.success) {
            dispatch(getLocationslice())
            setOpen(false)
            myForm.resetFields()
            message.success("Location added successfully")
            form.resetFields()
          } else {
            message.error(response.payload.message)
          }
        }).catch((err) => {
          console.error(err)
        })
    }
    catch (err) {
      message.error(err, "add Location error")
    }
  }

  //  Location delete 
  const handleDeleteLocation = (id) => {
    try {
      dispatch(deleteLocationSlice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload.isdelete) {
            dispatch(getLocationslice())
            message.success("Location deleted successfully")
          } else {
            message.error(`Can't delete this record as other items are associated with it.`)
          }
        } else {
          message.error(response.payload.message)
        }
      })
    }
    catch (error) {
      message.error(error, "error delete time")
    }
  }
  const handleResetSite = async () => {
    try {
      myForm.resetFields()
      setOpen(false);
      setEditModel(false)
    } catch (error) {
      console.error(error)
    }
  }
  const handleEditShow = (record, id, name) => {
    setEditModel(true)
    setEditRecord(record)
  }

  // site drop downn 
      const siteDropDownData = siteData.map((data, index) => {
        return (
          <Option key={index} value={data._id}>{data.name}</Option>
        )
      });



  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record, id) => {
        return (
          <>
            {(page - 1) * 10 + (id + 1)}
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record, id) => {
        return (
          <>
            {toUpper(record.name)}
          </>
        )
      }
    },
    {
      title: 'Site Name',
      dataIndex: 'sitename',
      render: (_, record) => {
        return (
          <>
            {record.warehouseId ? record.warehouseId.name : "-"}
          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const text = 'Are you sure to delete this Item ?';
        return (
          <>
            <span>

              <Button className="btn_edit mr_1" onClick={() => handleEditShow(record, record._id, record.warehouseId.name)} icon={<EditIcon />} />

              <Popconfirm placement="topRight" title={text} onConfirm={() => handleDeleteLocation(record._id)} okText="Yes" cancelText="No" >
                <Button className="btn_delete" icon={<DeleteIcon />} />
              </Popconfirm>
            </span>
          </>
        )
      }
    }

  ];

  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <div className='main_wrapper'>
          <div className="site_header">
            <label className='content_heading'><h2>Location Details</h2></label>
            <Button className='add_btn' size="large" onClick={() => setOpen(true)}><span><PlusOutlined /></span> Add New</Button>
          </div>

          {/* location Table  */}
          <Table columns={columns} dataSource={myLocationData} bordered sticky={true} size="middle" pagination={{ onChange(current) { setPage(current); } }} className='tabel_height main_table' />
          <Outlet />
        </div>
      </Spin>


      {/* location Edit modal  */}
      <Modal open={editModel} maskClosable={false} title="Edit location" width={550} className='site_title' onCancel={() => handleResetSite()} footer={null} >
        <EditLocation editRecord={editRecord} siteDropDownData={siteDropDownData} setEditModel={setEditModel} addLoader={addLoader} />
      </Modal>


      {/* location  add modal  */}
      <Modal maskClosable={false} title="Add new location" footer={null} className='site_title' open={open} onCancel={() => handleResetSite()}  >
        <Spin size='large' spinning={addLoader}>
          <Form layout="vertical" form={myForm} onFinish={handleAddLocation}>
            <div className='model_wrapper_main'>
              <div className='site_form'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Location name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Location name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name="name"
                    >
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className='form_control'
                      name="warehouseId"
                      label={
                        <span className="product_lable">Site name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select site name",
                        },
                      ]}   >
                      <Select className='site_input_group'>
                        {siteDropDownData}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="third_row">
                  <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>Cancel</Button>
                  <Button className='add_site_btn' size="large" htmlType='submit'>Save</Button>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default Location