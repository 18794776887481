import { api } from './index'
import setAuthToken from "./headers"
import errorFunction from './error'

const getAllEmployee = async() =>{
    return await api.get("api/v1/staff", {headers: setAuthToken()})
}

// Add Employee Lisiting
const addEmployee = async(data) =>{
    try{
        return await api.post("api/v1/staff", data, {headers: setAuthToken()})        
    } catch(err){
        console.error(err)
        return errorFunction(err.response.status, err)
    }
}
const deleteEmployeeById = async(id)=>{
    return await api.delete(`/api/v1/staff/${id}`,{headers: setAuthToken()})
}
const updateEmployeeById = async(data,id)=>{
    try {
        return await api.put(`/api/v1/staff/${id}`, data,  {headers: setAuthToken()}) 
    }catch(err){
        console.error(err)
        return errorFunction(err.response.status, err)
    }
}


const employeeService = {
    getAllEmployee,
    addEmployee,
    deleteEmployeeById,
    updateEmployeeById
}

export default employeeService;