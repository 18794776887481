import React, { useState } from 'react';
import { Button, Form, message, Spin, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { postBulkImageslice } from '../../redux/state/bulkdataSlice'
import { getProductslice } from '../../redux/state/productSlice';
import { UploadIcon } from '../../assets/icons/uploadIcon'
// const { Dragger } = Upload;
// const textforConfirm = "Are you sure with import product"
// const { Panel } = Collapse;


const ImageUpload = ({ setAllData, myImageForm, setImageView }) => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [shouldUpload, setShouldUpload] = useState(true);

    const dispatch = useDispatch(0)
    const onRemove = () => {
        try {
            setFileList([]);
            setAllData([])
        } catch (error) {
            console.error(error)
        }
    }

    const beforeUpload = (info) => {
        if (info && info.size > 1000000000) {
            message.error('File size should not be greater than 1 GB!');
            setShouldUpload(false)
            return;
        } else {

            setShouldUpload(true)
        }
    }


    const onChange = (info) => {
        if (info.fileList.length !== 0) {
            setFileList(info?.file?.originFileObj)
        } else {
            setFileList([])
        }
    }
    const handleReset = () => {
        setFileList([])
        myImageForm.resetFields()
        setImageView(false)


    }

    const handleFileUpload = () => {
        if (shouldUpload) {
            setUploading(true);
            const formData = new FormData();
            formData.append("productzip", fileList);
            dispatch(postBulkImageslice(formData)).then((result) => {
                if (result?.payload?.success == true) {
                    setUploading(false);
                    message.success(result?.payload?.message)
                    dispatch(getProductslice())
                    myImageForm.resetFields()
                    setImageView(false)
                } else if (result?.payload?.success == false) {
                    setUploading(false);
                    message.error(result?.payload?.message)
                } else {
                    setUploading(false);
                    message.error(result?.payload?.message || "Something went wrong!")
                }
            }
            )
        } else {
            return message.error('File size should not be greater than 1 GB!');
        }
    }

    return (
        <>
            <Spin spinning={uploading} size="large">
                <Form onFinish={handleFileUpload} form={myImageForm} layout='vertical' >

                    <div className='model_wrapper_main'>
                        <div className="site_header">
                            <label className='content_heading'><h2>Import product images - Select file</h2></label>
                        </div>
                        <hr className='new1' />
                        <div>
                            <Form.Item>
                                <p>Download a sample <a href="https://network-rv.s3.ap-south-1.amazonaws.com/sampleProducts.zip" className='sample_btn'>.zip format </a>
                                    file and compare it with your import file to ensure that the file is ready to import.</p>
                            </Form.Item>
                            <div className="record_file">
                                <Form.Item
                                    name="productzip"
                                    rules={[
                                        {
                                            required: true,
                                            message: "please upload zip file"
                                        }
                                    ]}
                                >
                                    <div className="file-input">
                                        <Upload
                                            beforeUpload={beforeUpload}
                                            onChange={onChange}
                                            onRemove={onRemove}
                                            accept=".zip"
                                            maxCount={1}
                                        >
                                            {fileList.length >= 1 ? null : (
                                                <div>
                                                    <UploadIcon />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Select File
                                                    </div>
                                                </div>
                                            )}
                                        </Upload>
                                    </div>
                                </Form.Item>

                            </div>
                            <div className='info_text'>
                                <p>Max file upload limit : 1 GB</p>
                            </div>
                            <div className="third_row bulk1_btn">
                                <Button className='Cancel_btn' size="large" onClick={() => handleReset()}>Cancel</Button>
                                <Button
                                    htmlType='submit'
                                    // loading={uploading}
                                    className="add_site_btn"
                                    size="large"
                                >
                                    Upload {/* {uploading ? 'Uploading' : 'Upload'} */}
                                </Button>
                            </div>
                        </div>

                    </div>

                    <br />

                </Form>
            </Spin>
        </>
    )
}

export default ImageUpload