

export const CheckMark = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="2" fill="#72B41A" />
                <path d="M7.39644 12.8625L4.14644 9.81174C3.95119 9.62846 3.95119 9.33128 4.14644 9.14798L4.85353 8.48422C5.04879 8.30091 5.36539 8.30091 5.56064 8.48422L7.75 10.5393L12.4394 6.13746C12.6346 5.95418 12.9512 5.95418 13.1465 6.13746L13.8536 6.80123C14.0488 6.98451 14.0488 7.28169 13.8536 7.46499L8.10355 12.8625C7.90828 13.0458 7.5917 13.0458 7.39644 12.8625V12.8625Z" fill="white" />
            </svg>
        </>
    )
}