import { api } from './index';
import setAuthToken from "./headers"

const postbulkfiledata = async (file, action) => {
    try {
        return await api.post(`api/v1/upload-product-csv/${action}`, file, { headers: { ...setAuthToken(), "Content-Type": "multipart/form-data" } })
    } catch (err) {
        if (err.response.data) {
            return err.response;
        } else { return {} }
    }
}

const postNewbulkfiledata = async (data) => {
    try {
        return await api.post(`api/v1/upload-product-bulck`, data, { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}
const postbulkImagefiledata = async (file) => {
    try {
        return await api.post(`api/v1/upload-product-zip`, file, { headers: { ...setAuthToken(), "Content-Type": "multipart/form-data" } })
    } catch (err) {
        console.log(err)
    }
}
const postbulkTemplatedata = async (file) => {
    try {
        return await api.post(`api/v1/upload-template-csv`, file, { headers: { ...setAuthToken(), "Content-Type": "multipart/form-data" } })
    } catch (err) {
        if (err.response.data) {
            return err.response;
        } else { return {} }
    }
}




const bulkdataService = {
    postbulkfiledata,
    postNewbulkfiledata,
    postbulkImagefiledata,
    postbulkTemplatedata
}

export default bulkdataService;