import { Layout, Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom'
import { DashboardOutlined, FileDoneOutlined, IdcardOutlined, PicCenterOutlined, ProjectOutlined, ShopOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import './index.css'
const { Sider } = Layout;


const Sidebar = () => {
  return (
    <>
      <Sider trigger={null} className="sider"  >
        <Menu
          className='sider_menu '
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: "Dashboard",
              icon: <DashboardOutlined />,
              label: <NavLink to="/" className="profile_item active" aria-current="page">Dashboard</NavLink>,
            },
            {
              key: "Location",
              icon: <ShopOutlined />,
              label: <NavLink to="/location" className="profile_item" aria-current="page">Locations</NavLink>,
            },
            {
              key: "Category",
              icon: <UnorderedListOutlined />,
              label: <NavLink to="/Category" className="profile_item" aria-current="page">Categories</NavLink>
            },
            {
              key: "Products",
              icon: <ProjectOutlined />,
              label: <NavLink to="/product" className="profile_item " aria-current="page">Products</NavLink>
            },
            {
              key: "Employee",
              icon: <UserOutlined />,
              label: <NavLink to="/employee" className="profile_item " aria-current="page">Employees</NavLink>
            },
            {
              key: "Jobcard",
              icon: <IdcardOutlined />,
              label: <NavLink to="/jobcard" className="profile_item " aria-current="page">Job Cards</NavLink>
            },
            {
              key: "Template",
              icon: <PicCenterOutlined />,
              label: <NavLink to="/template" className="profile_item " aria-current="page">Template</NavLink>
            },
            {
              key: "Reports",
              icon: <FileDoneOutlined />,
              label: <NavLink to="/report" className="profile_item " aria-current="page">Reports</NavLink>
            }
          ]}
        />
      </Sider>
    </>
  )
}

export default Sidebar