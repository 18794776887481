import { api } from './index';
import setAuthToken from "./headers"

const getLowproduct = async()=>{
    try {
        return await api.get("api/v1/low-product",{headers: setAuthToken()}) 
    } catch(err){
        console.log(err)
    }
}


const lowProductService = {
    getLowproduct,

}

export default lowProductService;