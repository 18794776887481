import { NavLink, } from "react-router-dom";
import React, { useEffect } from 'react';
import { Layout, Dropdown, Menu, message, Avatar } from "antd";
import moment from "moment";
import './index.css';
import { useNavigate } from "react-router-dom";
import Logo from '../../../assets/icons/Logo';
import profile from '../../../assets/images/profile.png';
const { Header } = Layout;



const HeaderComp = () => {

    const { name } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {}
    const navigate = useNavigate()
    useEffect(() => {
    }, [])

    const logout = () => {
        localStorage.clear();
        message.success("Logout successfully")
        navigate("/login", { replace: true })
    }
    // const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric', };

    // const currDate = new Date().toLocaleDateString(options);
    const currDate = moment().format("dddd,  DD MMMM YYYY")
    const menu = (
        <Menu
            items={[

                {
                    key: '1',
                    label: (
                        <NavLink to="/site" aria-current="page">Site</NavLink>
                    ),
                }
                ,
                {
                    key: '3',
                    label: (
                        <a rel="noopener noreferrer" onClick={(e) => logout(e)} >
                            Logout
                        </a>
                    ),
                }
            ]
            }
        />

    )
    const menus = (
        <Menu className="profile_dropdown">
            <Menu.Item key="0">
                <div className="profile_name">
                    <h2>Hello, {name}</h2>
                    <p><span><p className="date_formate">{currDate}</p></span></p>
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1">
                <NavLink to="/site" aria-current="page">Site</NavLink>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => logout()} >Logout</Menu.Item>
        </Menu>
    );
    return (
        <>
            <Header className="site-layout-background header_wrapper" style={{ padding: 0 }}>
                <div className="top_header">
                    <div className="sider_logo" >
                        <NavLink to="/" className="logo_item active" aria-current="page"><Logo /></NavLink>
                    </div>
                    <div>
                        <div>
                            <Dropdown overlay={menus} trigger={['click']}>
                                <a className="ant-dropdown-link" href="#">
                                    <Avatar src={profile} size={40} />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {/* <div className="Profile_item">
                <Row>
                
                    <Col span={24} className="user_icon">
                        
                    </Col>
                    <Col span={19}>
                        <NavLink to={`/`} className="profile_link active" aria-current="page">
                            <Dropdown className="header_profile_link" overlay={menu} placement="bottomLeft">
                                <Button> <p><span className="welcome_text"> Welcome, </span> Liam Harrison </p><span><p className="date_formate">{currDate}</p></span></Button>
                            </Dropdown></NavLink>
                    </Col>
                </Row>
            </div> */}
            </Header>
        </>
    )
}

export default HeaderComp