import React from 'react'
import { Col, Row } from 'antd'
import { toUpper } from 'lodash'


//additional commnet
const ViewProduct = ({ viewRecord }) => {
  const { name, qty, supplier, legacySKU, price, lowQty, sku, received_as, picked_as, description, supplierBarcode } = viewRecord

  return (
    <>
      <div className='model_wrapper_main'>
        <div className="first_product_row">
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col className="democlass" span={12 }>
              <div className='pro_list'>
                <span className="product_lable_1">Product name</span>
                <p className="site_input_group_1">{name}</p>
              </div>
            </Col>
            <Col span={12 }>
              <div className='pro_list'>
                <span className="product_lable_1">SKU</span>
                <p className="site_input_group_1">{sku}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">SKU</span>
                <p className="site_input_group_1">{sku}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12 }>
              <div className='pro_list'>
                <span className="product_lable_1">Quantity</span>
                <p className="site_input_group_1">{qty ? qty : 0}</p>
              </div>
            </Col>
            <Col span={12 }>
              <div className='pro_list'>
                <span className="product_lable_1">Price(Inc.GST)</span>
                <p className="site_input_group_1">{price}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
        </div>

        <div className="first_product_row">
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Price(Inc.GST)</span>
                <p className="site_input_group_1">{price}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Location</span>
                <p className="site_input_group_1">{(viewRecord?.locationId?.name) ? toUpper(viewRecord?.locationId?.name) : toUpper(viewRecord?.categoryId?.name)}</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='pro_list'>
                <span className="product_lable_1">Category</span>
                <p className="site_input_group_1">{toUpper(viewRecord?.categoryId?.name)}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Category</span>
                <p className="site_input_group_1">{toUpper(viewRecord?.categoryId?.name)}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12}>
              <div className='pro_list'>
                <span className="product_lable_1">Supplier name</span>
                <p className="site_input_group_1">{supplier}</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='pro_list'>
                <span className="product_lable_1">Legacy SKU</span>
                <p className="site_input_group_1">{legacySKU}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
        </div>

        <div className="first_product_row">
          {/* <Row gutter={16}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Legacy SKU</span>
                <p className="site_input_group_1">{legacySKU}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Low stock</span>
                <p className="site_input_group_1">{lowQty ? lowQty : 0}</p>
              </div>
            </Col>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Received as</span>
                <p className="site_input_group_1">{received_as ? received_as : "-"}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Received as</span>
                <p className="site_input_group_1">{received_as ? received_as : "-"}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Picked as</span>
                <p className="site_input_group_1">{picked_as ? picked_as : "-"}</p>
              </div>
            </Col>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Supplier barcode</span>
                <p className="site_input_group_1">{supplierBarcode ? supplierBarcode : "-"}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Supplier barcode</span>
                <p className="site_input_group_1">{supplierBarcode ? supplierBarcode : "-"}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
          <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Description</span>
                <p className="site_input_group_2">{description ? description : '-'}</p>
              </div>
            </Col>
            <Col span={12} >
              <div className='pro_list'>
                <span className="product_lable_1">Image</span>
                <p className="site_input_group_1">{(viewRecord?.image == undefined || viewRecord?.image == "") ? "No-image" : <img src={viewRecord.image} width="85px" height="75px" alt="" />}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' />
          {/* <Row gutter={[32,{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col >
              <div className='pro_list'>
                <span className="product_lable_1">Image</span>
                <p className="site_input_group_1">{(viewRecord?.image == undefined || viewRecord?.image == "") ? "No-image" : <img src={viewRecord.image} width="85px" height="75px" alt="" />}</p>
              </div>
            </Col>
          </Row>
          <hr className='new1' /> */}
        </div>
      </div>
    </>
  )
}

export default ViewProduct