import { api } from './index'
import setAuthToken from "./headers"
import errorFunction from './error'

const getAllJob = async () => {
    try {
        return await api.get("api/v1/webpendingjobcard", { headers: setAuthToken() })
    } catch (err) {
        return errorFunction(err.response.status, err)
    }
}
const addJobcard = async (data) => {
    try {
        return await api.post("api/v1/jobcard", data, { headers: setAuthToken() })
    } catch (err) {
        return errorFunction(err.response.status, err)
    }
}

const deleteJobcadById = async (id) => {
    try {
        return await api.delete(`api/v1/webpendingjobcard/${id}`, { headers: setAuthToken() })
    } catch (err) {
        console.error(err)
    }
}

const getProductItemDetails = async (id) => {
    try {
        return await api.get(`api/v1/jobcardItems/${id}`, { headers: setAuthToken() })
    } catch (err) {
        console.error(err)
    }
}
const getsingleJobById = async (id) => {
    return await api.get(`api/v1/jobcardItems/${id}`, { headers: setAuthToken() })
}

const updateJobById = async (data, id) => {
    try {
        return await api.put(`api/v1/jobcard/${id}`, data, { headers: setAuthToken() })
    } catch (err) {
        return errorFunction(err.response.status, err)
    }
}

const jobService = {
    getAllJob,
    addJobcard,
    deleteJobcadById,
    getProductItemDetails,
    updateJobById,
    getsingleJobById

}

export default jobService;