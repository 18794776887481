export default function setAuthToken(){
    const accesToken = localStorage.getItem('token')
    if (accesToken) {
        return {
            Accept: "application/json",
            'Content-Type': 'application/json',
            'Authorization':JSON.parse(accesToken)
        }
    } else{
        return {
            'Content-Type': 'application/json',
        }
    }
}

