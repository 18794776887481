import React from 'react'
import { Col, InputNumber, message, Row, Select, Spin, Upload, } from 'antd';
import '../Products/product.css'
import { Button, Form } from 'antd';
import Input from '../common/Index'

import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input


const AddProduct = ({ myForm, addLoader, handleAddProduct, fileList, setFileList, locationdropdown, categorydropdown, setOpen }) => {


  const handleResetSite = async () => {
    try {
      myForm.resetFields()
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }




  const beforeUpload = (file) => {
    const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

  }
  const handleRemove = (fl) => {
    setFileList([]);

  };


  const formatNumber = value => {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const parseNumber = value => {
    if (value) {
      return value.toString().replace(/,/g, '');
    }
    return undefined;
  };


  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form layout="vertical" form={myForm} onFinish={handleAddProduct} >
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Product name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Product name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='name'>
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">SKU</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "SKU id is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='sku'>
                      <Input maxLength={80} className='site_input_group' inputtype="alphanumnospace" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control form_prefix'
                      label={
                        <span className="product_lable">Price (Inc.GST)</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Price is required",
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(rule, value) {
                        //     if (!value || getFieldValue("price") > 0) {
                        //       return Promise.resolve();
                        //     }
                        //     return Promise.reject("Price is invalid");
                        //   },
                        // })
                      ]}
                      name="price">
                      <InputNumber prefix="$" formatter={formatNumber}
                        parser={parseNumber} inputtype="number" allowdecimal={true} className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Category</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select category",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='categoryId' >
                      <Select className='site_input_group'>
                        {categorydropdown}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Location</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please select location",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='locationId' >
                      <Select className='site_input_group'>
                        {locationdropdown}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Low stock</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Low stock is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("lowQty") > 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Low stock is invalid");
                          },
                        })
                      ]}
                      name='lowQty' initialValue={0} >
                      <Input inputtype='number' min='0' max="100000" className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Supplier name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Supplier name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name="supplier" >
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Legacy SKU</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "LegacySKU is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate legacySKU!",
                        },
                      ]}
                      name='legacySKU'>
                      <Input type='text' maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control form_prefix'
                      label={
                        <span className="product_lable">Last price</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Last price is required",
                        }
                      ]}
                      name="lastPrice">
                      <InputNumber prefix="$ " formatter={formatNumber}
                        parser={parseNumber} inputtype="number" allowdecimal={true} className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className='first_product_row'>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Received as</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Recived as is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='received_as'>
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Picked as</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Picked as is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='picked_as'>
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Supplier barcode</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Supplier barcode is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='supplierBarcode'>
                      <Input maxLength={80} className='site_input_group' inputtype="alphanumnospace" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="second_row">
                <Row gutter={24}>
                  <Col span={16}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Product description</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please input Descrip..",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='description' >
                      <TextArea
                        autoSize={{
                          minRows: 2,
                          maxRows: 6,

                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="image"
                    >
                      <Upload
                        listType="picture"
                        accept='.png, .jpg'
                        defaultFileList={[...fileList]}
                        className="image_lists"
                        maxCount={1}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        onRemove={handleRemove}

                        fileList={fileList}
                      >
                        <span> <Button icon={<UploadOutlined />}>Upload</Button> <p>(Upload only jpg/png file)</p></span>

                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>Cancel</Button>
                <Button className='add_site_btn' size="large" htmlType='submit'>Save</Button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default AddProduct
