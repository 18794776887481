import React, { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Dropdown, Input, Popconfirm, Space, Spin } from "antd";
import "../sites/sites.css";
import { Table, Button, Modal, Select, message, Form } from "antd";
import { EditIcon } from "../../assets/icons/EditIcon";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductslice,
  addProductSlice,
  deleteProductSlice,
} from "../../redux/state/productSlice";
import {
  getLocationDropSlice,
  getCategoryDropSlice,
} from "../../redux/state/dropdownSlice";
import AddProduct from "./addProduct";
import EditProduct from "./editProduct";
import ViewProduct from "./viewProduct";
import { ViewIcon } from "../../assets/icons/ViewIcon";
import BulkUpload from "./bulkupload";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ImageUpload from "./imageUpload";
import { round, toUpper } from "lodash";
import Highlighter from "react-highlight-words";
import { render } from "@testing-library/react";
import { ResizableBox } from "react-resizable";
import Draggable from "react-draggable";

const { Option } = Select;

const items = [
  { label: "item 1", key: "item-1" },
  { label: "item 1", key: "item-2" },
];

const getBase64 = async (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (error) {
    cb(error);
  };
};

const beforeUpload = (file) => {
  const isJpgOrPng = file?.type === "image/jpeg" || file?.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
};

const Products = () => {
  const dispatch = useDispatch();
  const [myProuctData, setMyProductData] = useState([]);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [viewRecord, setViewRecord] = useState([]);
  const [status, setStatus] = useState(true);
  const [location, setLocation] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryForFilter, setCategoryForFilter] = useState([]);
  // const [categorys, setCategorys] = useState([])
  const [editModel, setEditModel] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [page, setPage] = useState(1);
  const [fileList, setFileList] = useState([]);
  const [bulkView, setBulkView] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [change, setchange] = useState(false);
  const [allData, setAllData] = useState([]);

  const [myForm] = Form.useForm();
  const [myBulkForm] = Form.useForm();
  const [myImageForm] = Form.useForm();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const searchInput = useRef(null);
  const [size, setSize] = useState({ width: 800, height: 400 });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [toggle, setToggle] = useState(true);
  const draggleRef = useRef(null);

  // TABLE SEARCHING START

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    console.log("first");
    setSearchText("");
    clearFilters();
    close();
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              confirm &&
              handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  //  SEARCHING END

  useEffect(() => {
    dispatch(getProductslice());
    dispatch(getLocationDropSlice());
    dispatch(getCategoryDropSlice());
  }, [dispatch]);

  const { products, isLoading: addLoader } = useSelector(
    (state) => state.products
  );
  const { locationdropdowns } = useSelector((data) => data.sitedropdowns);
  const { categorydropdowns } = useSelector((data) => data.sitedropdowns);

  useEffect(() => {
    if (products && products.data) {
      const dd = products.data.map((e) => {
        return { ...e, key: e._id };
      });
      setMyProductData(dd);
    }
    if (locationdropdowns && locationdropdowns.data) {
      const drpdown = locationdropdowns.data.map((e) => {
        return { ...e, key: e._id };
      });
      setLocation(drpdown);
    }
    if (categorydropdowns && categorydropdowns.data) {
      const drpdown = categorydropdowns.data.map((e) => {
        return { ...e, key: e._id };
      });
      setCategory(drpdown);
      setCategoryForFilter(() => {
        return drpdown.map((item) => {
          return {
            text: toUpper(item.name),
            value: item.name,
          };
        });
      });
    }
  }, [products, locationdropdowns, categorydropdowns]);

  // location  drop down
  const locationDropDownData = location.map((data, index) => {
    return (
      <Option key={index} value={data._id}>
        {toUpper(data.name)}
      </Option>
    );
  });

  // category drop down
  const categoryDropDown = category.map((data, index) => {
    // setCategorys(data);

    return (
      <Option key={index} value={data._id}>
        {toUpper(data.name)}
      </Option>
    );
  });

  const saveExp = (dd) => {
    dd.lowQty = dd.lowQty == "" ? 0 : dd.lowQty;
    dispatch(addProductSlice(dd))
      .then((response) => {
        if (response.payload.success) {
          message.success("Product added successfully");
          dispatch(getProductslice());
          myForm.resetFields();
          setOpen(false);
        } else {
          message.error(response.payload.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // add product module
  const handleAddProduct = (values) => {
    values = { ...values, is_active: status };
    let sendData = values;
    if (values.image) {
      getBase64(fileList[0].originFileObj, (result) => {
        if (result.startsWith("data:image")) {
          sendData.image = result;
          saveExp(sendData);
        } else {
          beforeUpload();
        }
      });
    } else {
      delete sendData.image;
      saveExp(sendData);
    }
  };

  //  Product delete
  const handleDeleteProduct = (id) => {
    try {
      dispatch(deleteProductSlice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload.isdelete) {
            dispatch(getProductslice());
            message.success("Product deleted successfully");
          } else {
            message.error(
              `Can't delete this record as other items are associated with it.`
            );
          }
        } else {
          message.error(response.payload.message);
        }
      });
    } catch (error) {
      message.error(error, "error delete time");
    }
  };

  // Edit Product Module
  const handleEditShow = (record, id) => {
    setEditModel(true);
    setEditRecord(record);
  };

  const handleResetSite = async () => {
    try {
      myForm.resetFields();
      setEditModel(false);
      setOpen(false);
      setSize({ width: 800, height: 400 });
      setToggle("true")
    } catch (error) {
      message.error(error);
    }
  };

  const ViewProductItem = (record) => {
    setView(true);
    setViewRecord(record);
  };
  const handleResetSiteBulk = () => {
    setAllData([]);
    myBulkForm.resetFields();
    setBulkView(false);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: "Id",
      // width: "10%",
      render: (_, record, id) => {
        return <>{(page - 1) * 10 + (id + 1)}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "Name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      // width: "20%",
      ellipsis: true,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      // width: "15%",
      ...getColumnSearchProps("sku", "SKU"),
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      ellipsis: true,
    },
    {
      title: "Quantity",
      // width: "10%",
      render: (_, record, id) => {
        return <>{record.qty ? record.qty : 0}</>;
      },
    },
    {
      title: "Price",
      // width: "15%",
      render: (_, record, id) => {
        const prices = record?.price
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return <>{record.price ? `$ ${prices}` : "-"}</>;
      },
    },
    {
      title: "Category",
      // width: "15%",
      filters: categoryForFilter,
      filterSearch: true,
      onFilter: (value, record) => record?.categoryId?.name.startsWith(value),
      sorter: (a, b) => a?.categoryId?.name.localeCompare(b?.categoryId?.name),
      ellipsis: true,
      render: (_, record) => {
        return <>{record.categoryId ? toUpper(record.categoryId.name) : "-"}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      // width: "15%",
      render: (_, record) => {
        const text = "Are you sure to delete this Item ?";
        return (
          <>
            <div style={{ display: "flex" }}>
              <Button
                className="btn_view mr_1"
                onClick={() => ViewProductItem(record)}
                icon={<ViewIcon />}
              />
              <Button
                className="btn_edit mr_1"
                onClick={() => handleEditShow(record, record._id)}
                icon={<EditIcon />}
              />
              <Popconfirm
                placement="topRight"
                title={text}
                onConfirm={() => handleDeleteProduct(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="btn_delete" icon={<DeleteIcon />} />
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setBulkView(true)}
        >
          {/* <span><PlusOutlined /></span>  */}
          Import Product
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setImageView(true)}
        >
          Import Image
        </a>
      ),
    },
  ];

  //resize modal
  const handleResize = (event, { size }) => {
    setSize(size);
  };
  //dragable modal
  const { clientWidth, clientHeight } = window.document.documentElement;

  const onStart = (_event, uiData) => {
    console.log("clientHeight,clientWidth :>> ", clientHeight, clientWidth);
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x + 10),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y + 20),
    });
  };

  const fullWidthAdjustable = async () => {
    if (toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      dragableElement.classList.add("topleftmodal");
      setSize({ width: clientWidth - 100, height: clientHeight - 130 });
    }
    setToggle(false);
  };
  const minWidthAdjustable = async () => {
    setToggle(true);
    if (!toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      console.log("dragableElement :>> ", dragableElement);
      dragableElement.classList.remove("topleftmodal");
      setSize({ width: 800, height: 400 });
    }
  };

  return (
    <>
      <Spin size="large" spinning={addLoader}>
        <div className="main_wrapper">
          <div className="site_header">
            <label className="content_heading">
              <h2>Product Details</h2>
            </label>

            <div className="product_btns">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Button className="bulk_btn" size="large">
                      <span>
                        <PlusOutlined />
                      </span>{" "}
                      Import
                    </Button>
                  </Space>
                </a>
              </Dropdown>
              <Button
                className="add_btn extra"
                size="large"
                onClick={() => setOpen(true)}
              >
                <span>
                  <PlusOutlined />
                </span>{" "}
                Add New
              </Button>
            </div>
          </div>

          {/* Product Table  */}
          <Table
            columns={columns}
            dataSource={myProuctData}
            bordered
            size="middle"
            pageSize={false}
            showSizeChanger={false}
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            scroll={{ y: 800 }}
            className="tabel_height main_table"
            onChange={onChange}
          />
          <Outlet />
        </div>
      </Spin>

      {/* Product View Moddal  */}
      <Modal
        title="View product details"
        footer={null}
        open={view}
        width={800}
        onCancel={() => setView(false)}
        className="job_product_data"
      >
        <ViewProduct viewRecord={viewRecord} />
      </Modal>

      {/* Bulk Upload View  */}
      <Modal
        title="Product import"
        footer={null}
        open={bulkView}
        maskClosable={false}
        width={850}
        onCancel={() => handleResetSiteBulk()}
      >
        <BulkUpload
          setBulkView={setBulkView}
          allData={allData}
          myBulkForm={myBulkForm}
          setAllData={setAllData}
        />
      </Modal>

      {/* Image Upload View  */}
      <Modal
        title="Image import"
        footer={null}
        open={imageView}
        maskClosable={false}
        width={850}
        onCancel={() => setImageView(false)}
      >
        <ImageUpload
          setImageView={setImageView}
          allData={allData}
          myImageForm={myImageForm}
          setAllData={setAllData}
        />
      </Modal>

      {/* Product edit modal  */}
      <Modal
        open={editModel}
        maskClosable={false}
        title={
          <div className="title_div">
            <div
              style={{
                width: "96%",
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              Edit product
            </div>
            {toggle ? (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={fullWidthAdjustable}
              >
                <FullscreenOutlined />
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={minWidthAdjustable}
              >
                <FullscreenExitOutlined />
              </div>
            )}
          </div>
        }
        width={size.width + 40}
        height={200}
        onCancel={() => handleResetSite()}
        className="site_title job_product_data"
        footer={null}
        centered={false}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <ResizableBox
          width={size.width}
          height={size.height}
          onResize={handleResize}
          minConstraints={[900, 400]}
          maxConstraints={[clientWidth - 100, clientHeight - 50]}
        >
          <EditProduct
            editRecord={editRecord}
            change={change}
            setchange={setchange}
            locationdropdown={locationDropDownData}
            categorydropdown={categoryDropDown}
            setEditModel={setEditModel}
            addLoader={addLoader}
          />
        </ResizableBox>
      </Modal>

      {/* Product add modal  */}
      <Modal
        maskClosable={false}
        title={
          <div className="title_div">
            <div
              style={{
                width: "96%",
                cursor: "move",
                // background:"red",
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              Add new product
            </div>
            {toggle ? (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={fullWidthAdjustable}
              >
                <FullscreenOutlined />
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={minWidthAdjustable}
              >
                <FullscreenExitOutlined />
              </div>
            )}
          </div>
        }
        className="site_title job_product_data"
        footer={null}
        open={open}
        width={size.width + 40}
        onCancel={() => handleResetSite()}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
      <ResizableBox
          width={size.width}
          height={size.height}
          onResize={handleResize}
          minConstraints={[900, 400]}
          maxConstraints={[clientWidth - 100, clientHeight - 50]}
        >
        <AddProduct
          myForm={myForm}
          addLoader={addLoader}
          fileList={fileList}
          setFileList={setFileList}
          locationdropdown={locationDropDownData}
          categorydropdown={categoryDropDown}
          handleAddProduct={handleAddProduct}
          setOpen={setOpen}
          status={status}
          setStatus={setStatus}
        />
        </ResizableBox>
      </Modal>
    </>
  );
};

export default Products;
