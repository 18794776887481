import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import loginService from '../api/LoginApi'


const initialState ={
    login:[],
    isLoading:false,
    error:null
}

export const loginslice = createAsyncThunk('login/retrive',async(data)=>{
    try{
        const response = await loginService.LoginData(data)
        return response.data ? response.data: response
    } catch(error){
        console.log(error)
    }
})

const  loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers:{
        [loginslice.pending]:(state,action)=>{
            state.isLoading = true
        },
        [loginslice.fulfilled]:(state,action)=>{
            state.isLoading = false
            state.sites = action.payload
        },
        [loginslice.rejected]:(state,action)=>{
            state.isLoading=false
            state.error = [action.payload]
        }

    }
});

export default loginSlice.reducer

