import React, { useState, useEffect } from 'react'
import { Button, Select, Spin, Form, message, Row, Col } from 'antd';
import Input from '../common/Index'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getProductDropSlice } from '../../redux/state/dropdownSlice';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { isNumber } from 'lodash';
import { getSingleTemplateSlice, getTemplateslice, updateTemplateSlice } from '../../redux/state/templateSlice';


const { Option } = Select;



const EditTemplate = ({ setEditModel, editRecord }) => {

    const [form] = Form.useForm();
    const [inputNumberValue, setInputNumberValue] = useState('')
    const [productData, setProductData] = useState([])
    const [editOldData, setEditOldData] = useState([])
    const [editProductRecord, setEditProductRecord] = useState([])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        dispatch(getSingleTemplateSlice(editRecord._id))
        dispatch(getProductDropSlice())
    }, [dispatch, id, editRecord]);


    const { productdropdowns, isLoading: addLoader } = useSelector(state => state.sitedropdowns)
    const { templatesItems } = useSelector(state => state.templates)

    useEffect(() => {


        if (templatesItems[0]?.success && templatesItems[0]?.data) {
            setEditProductRecord(templatesItems[0]?.data);
        }
        if (productdropdowns && productdropdowns.data) {
            const drpdown = productdropdowns.data.map((e) => {
                return { value: e._id, label: e.name }
            })
            setProductData(drpdown);
        }
    }, [templatesItems, productdropdowns])

    useEffect(() => {
        if (editProductRecord && editProductRecord?.templateItem?.length > 0) {
            const oldjobcardProductItem = editProductRecord?.templateItem.map((item, index) => {
                return {
                    product_name: item.productId.name ? item.productId._id : "",
                    comment: item.comment ? item.comment : "",
                    quantity: item.qty ? item.qty : "1"
                }
            })
            setEditOldData(oldjobcardProductItem)
            form.setFieldsValue({ templateItem: oldjobcardProductItem })
        }
    }, [editProductRecord])


    const handleResetSite = async () => {
        setEditModel(false)
    }

    // ADD Template data 
    const handleUpdateTemp = (values) => {
        const { templateName, templateItem } = values;
        if (!Array.isArray(templateItem) || templateItem == undefined || templateItem.length === 0) {
            message.error("Add at least one product")
            return
        }
        try {
            values = { ...values, id: editRecord._id }
            dispatch(updateTemplateSlice(values))
                .then((response) => {
                    if (response.payload.success === true) {
                        form.resetFields()
                        setEditModel(false)
                        dispatch(getTemplateslice())
                        navigate("/template", { replace: true })
                        message.success("Template edited successfully")
                    } else {
                        message.error(response.payload.message)
                    }
                }).catch((err) => {
                    console.error(err)
                })
        }
        catch (err) {
            message.error(err, "add Location error")
        }

    }


    return (
        <>
            {/* Job Discription  */}
            <Spin size='large' spinning={addLoader}>
                <Form layout='vertical' form={form} name="dynamic_form_nest_item" initialValues={{ templateName: editProductRecord.templateName }} onFinish={handleUpdateTemp} autoComplete="off">
        
                    <Form.Item

                        className='form_control chassis_no'
                        name="templateName"
                        label={
                            <>
                                <span className="product_lable_1">Template name : </span>
                                <p className="site_input_group_1 edit_chassis_no">{editProductRecord.templateName}</p>
                            </>
                        }>
                    </Form.Item>
                    <div className=''>
                        <Form.List name="templateItem" initialValue={editOldData}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (

                                        <>
                                            {
                                                form.getFieldValue('templateItem').map((element, index) => {
                                                    if (element !== undefined && element.product_name !== undefined) {
                                                    }
                                                })
                                            }
                                            <Row gutter={10} className="add_product" >
                                                <Col span={10}>
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        name={[field.name, 'product_name']}
                                                        placeholder="Select Product Name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Product name is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Select Product Name"
                                                            showSearch
                                                            options={productData}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3} >
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        name={[field.name, 'quantity']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Quantity is required',
                                                            }
                                                        ]}
                                                    >
                                                        <Input placeholder="Quantity" maxLength={6} inputtype="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={9}>
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        // label="Price"
                                                        name={[field.name, 'comment']}

                                                    >
                                                        <Input placeholder='Comment' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} className="remove_job">
                                                    <DeleteOutlined onClick={() => remove(field.name)} />

                                                </Col>
                                            </Row>
                                        </>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" className='add_new_product' onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Products
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                    <Form.Item>
                        <div className="third_row">
                            <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>
                                Cancel
                            </Button>
                            <Button className='add_site_btn' size="large" htmlType='submit'>
                                Update
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    )
}

export default EditTemplate
