import { api } from './index'
import setAuthToken from "./headers"
import errorFunction from './error'

const getAllCategory = async() =>{
    try{
        return await api.get("api/v1/category",{headers: setAuthToken()})
    } catch(err){
        console.error(err)
    }
}

// Add Category Api
const addCategory = async(data) =>{
    try{
        return await api.post("api/v1/category", data,{headers: setAuthToken()})        
    } catch(err){
        console.error(err)
        return errorFunction(err.response.status, err)

    }
}
const deleteCategoryById = async(id)=>{
    return await api.delete(`api/v1/category/${id}`,{headers: setAuthToken()})
}

const updateCategoryById = async(data,id)=>{
    try{
        return await api.put(`/api/v1/category/${id}`, data, {headers: setAuthToken()})
    } catch(err){
        console.error(err)
        return errorFunction(err.response.status, err)

    }
}


const categoryService = {
    getAllCategory,
    addCategory,
    deleteCategoryById,
    updateCategoryById

}

export default categoryService;