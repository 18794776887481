import React, { useState } from 'react';
import { Button, Form, message, Radio, Space, Spin, Table, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { postBulkTemplateslice, postTemplateslice } from '../../redux/state/bulkdataSlice'
import { getProductslice } from '../../redux/state/productSlice';
import { UploadIcon } from '../../assets/icons/uploadIcon'
import { useEffect } from 'react';
import { getTemplateslice } from '../../redux/state/templateSlice';
// const { Dragger } = Upload;
// const textforConfirm = "Are you sure with import product"
// const { Panel } = Collapse;


const columns = [
    {
        title: 'No',
        render: (_, record, i) => {
            return (<>
                {i + 1}
            </>)
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Sku',
        dataIndex: 'sku',
    },
];





const BulkUpload = ({ setBulkView, allData, setAllData, myBulkForm, checkTemp, next, setNext }) => {
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [validateData, setValidateData] = useState([])
    const [existproductCount, setExistproductCount] = useState([])
    const [newproductCount, setNewproductCount] = useState([])
    const [newProductData, setNewProductData] = useState([])
    const [actioncheck, setActioncheck] = useState(0)

    const [existBardcode, setExistBarcode] = useState([])
    const [templateFailedData, setTempateFailedData] = useState([])
    const [failedData, setFailedData] = useState([])


    const dispatch = useDispatch()
    // csv file

    const onRemove = () => {
        try {
            setFileList([]);
            setAllData([])
        } catch (error) {
            console.error(error)
        }
    }
    const onChange = (info) => {
        if (info.fileList.length !== 0) {

            setFileList(info?.file?.originFileObj)
        } else {
            setFileList([])
        }
    }

    useEffect(() => {
        if (!allData && allData.data.length <= 0) {
            setExistproductCount([])
        }
    }, [allData])

    useEffect(() => {
        console.log(templateFailedData)
        if (templateFailedData) {
            const dd = templateFailedData.map((e) => {
                return { ...e, key: e._id }
            })
            setFailedData(dd);
        }
    }, [templateFailedData])



    const onUpload = (value) => {
        setUploading(true);
        if (checkTemp === true) {
            const formData = new FormData();
            formData.append("templatecsv", fileList);
            dispatch(postBulkTemplateslice(formData)).then((res) => {
                console.log(res?.payload?.notUplodedData === [])
                if (res?.payload?.success === true && res?.payload?.notUplodedData === []) {
                    message.success(`New Template uploaded sucessfully`)
                    dispatch(getTemplateslice())
                    setUploading(false);
                    setBulkView(false)
                    myBulkForm.resetFields()
                } else if (res?.payload?.notUplodedData != []) {
                    console.log(res?.payload?.message === "Data updated !!")
                    if (res?.payload?.message === "Data updated !!") {
                        message.warning("Some updates failed. Please review the list for more information.")
                    } else {
                        message.error(res?.payload?.message || "Something went wrong!")
                    }
                    setUploading(false);
                    setNext(true)
                    dispatch(getTemplateslice())
                    setTempateFailedData(res.payload.data)
                    setTempateFailedData(res.payload.notUplodedData)
                } else {
                    setUploading(false);
                    message.error(res?.payload?.message || "Something went wrong!")
                }
            })
        } else {
            const formData = new FormData();
            formData.append("productcsv", fileList);
            dispatch(postTemplateslice({ formData, actioncheck })).then((result) => {
                if (result?.payload?.data) {
                    setValidateData(result?.payload?.data)
                    setNewproductCount(result?.payload?.newInsertProduct)
                    setExistproductCount(result?.payload?.exitsCount)
                    setExistBarcode(result?.payload?.existBarcode)
                    setAllData(result?.payload)
                    setNewProductData(result?.payload?.newData)
                    setUploading(false);
                    setNext(true)
                } else {
                    setUploading(false);
                    message.error(result?.payload?.message || "Something went wrong!")
                }
            })
        }
    }


    // with validation final  upload
    const handleFileUpload = () => {
        if (checkTemp === true) {
            dispatch(getTemplateslice())
            setNext(false)
            myBulkForm.resetFields()
            setBulkView(false)
            setAllData([])
        } else {
            const newdataObject = { newProductObject: newProductData }
            message.success(`New product uploaded sucessfully`)
            dispatch(getProductslice())
            setNext(false)
            myBulkForm.resetFields()
            setBulkView(false)
            setAllData([])

        }
    }
    const handleReset = () => {
        setNext(false)
        myBulkForm.resetFields()
        setBulkView(false)

    }


    const columns1 = [
        {
            title: 'Product Name',
            dataIndex: 'product_name',

        },
        {
            title: 'SKU',
            dataIndex: 'part_number',
            ellipsis: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            render: (_, record, i) => {
                return (
                    record.comment ? record.comment : "-"
                )
            },
            ellipsis: true,
        }
    ];


    return (<>

        <Form onFinish={onUpload} form={myBulkForm} layout='vertical' >
            <Spin spinning={uploading} size="large" >
                {next == true ? "" : <>
                    <div className='model_wrapper_main'>
                        <div className="site_header">
                            <label className='content_heading'><h2>Import products - Select file</h2></label>
                        </div>
                        <hr className='new1' />
                        <div>
                            <Form.Item>
                                {checkTemp === true ? <p>Download a sample <a href="https://itech-apk-files.s3.us-east-2.amazonaws.com/templatesample-apkver-1-date-04-05-2023.csv" className='sample_btn'>.csv format </a>
                                    file and compare it with your import file to ensure that the file is ready to import.</p> : <p>Download a sample <a href="https://network-rv.s3.ap-south-1.amazonaws.com/sampleProducts1.csv" className='sample_btn'>.csv format </a>
                                    file and compare it with your import file to ensure that the file is ready to import.</p>}
                            </Form.Item>
                            <div className="record_file">
                                <Form.Item
                                    name="productcsv"
                                >
                                    <div className="file-input">
                                        <Upload

                                            onChange={onChange}
                                            onRemove={onRemove}
                                            accept=".csv"
                                            maxCount={1}
                                        >
                                            {fileList.length >= 1 ? null : (
                                                <div>
                                                    <UploadIcon />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Select File
                                                    </div>
                                                </div>
                                            )}
                                        </Upload>
                                    </div>
                                </Form.Item>
                                {checkTemp ? "" : <Form.Item className="form_control" name="action"
                                    label={<>
                                        <span className="product_lable">How should duplicate entries be handled?</span>
                                    </>}
                                >
                                    <Radio.Group className="p-1" defaultValue={0} onChange={(e) => {
                                        setActioncheck(e.target.value);
                                    }}
                                    >
                                        <Space direction="vertical">
                                            <Radio className="form-check-label" name="before_purchase_type_change" value={0}>
                                                Skip
                                            </Radio>
                                            <Radio className="form-check-label" name="before_purchase_type_change" value={1}>
                                                Overwrite
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>}
                            </div>
                            <div className="third_row bulk1_btn">
                                <Button className='Cancel_btn' size="large" onClick={() => handleReset()}>Cancel</Button>
                                <Button
                                    htmlType='submit'
                                    // loading={uploading}
                                    className="add_site_btn"
                                    size="large"
                                >
                                    Next {/* {uploading ? 'Uploading' : 'Upload'} */}
                                </Button>
                            </div>
                        </div>

                    </div>
                </>}
                <br />
                <>
                    {(next == true) ?
                        <>
                            {!checkTemp ?
                                <>
                                    <div className="site_header">
                                        <label className='content_heading'><h2>Ready for import - Preview</h2></label>
                                    </div>
                                    <hr className='new1' /><br /><br />

                                    <label htmlFor="">Product details ready for import. </label>
                                    <span className='product_count'>{`(  ${newproductCount == undefined ? 0 : newproductCount} )`}</span><br /><br />

                                    <label htmlFor="">No. of records  skipped. </label>
                                    <span className='product_count'>{`(  ${existproductCount == undefined ? 0 : existproductCount} )`}</span><br /><br />

                                    <label htmlFor="">No. of records barcode skipped. </label>
                                    <span className='product_count'>{`(  ${existBardcode == undefined ? 0 : existBardcode.length} )`}</span><br /><br />


                                    <div>
                                        <div className="third_row">
                                            <Button className='Cancel_btn' size="large" onClick={() => handleReset()}>Cancel</Button>
                                            <Button className='update_site_btn' size="large" onClick={() => handleFileUpload()}>Import</Button>

                                        </div>
                                    </div>

                                </> : <>
                                    <div className="site_header">
                                        <label className='content_heading'><h2>Failed Product</h2></label>
                                    </div>
                                    <hr className='new1' />
                                    <Table dataSource={failedData} columns={columns1} />
                                    <div>
                                        <div className="third_row">
                                            <Button className='Cancel_btn' size="large" onClick={() => handleReset()}>Cancel</Button>
                                            <Button className='update_site_btn' size="large" onClick={() => handleFileUpload()}>Ok</Button>

                                        </div>
                                    </div>
                                </>
                            }

                        </> : ""}
                </>
            </Spin>
        </Form>
    </>
    )
};
export default BulkUpload;


