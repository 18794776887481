import { api } from './index'



const getSiteDropdown = async () => {
    try {
        return await api.get("api/v1/site-dropdown")
    } catch (err) {
        console.error(err)
    }
}

const getLocationDropdown = async () => {
    try {
        return await api.get("api/v1/location-dropdown")
    } catch (err) {
        console.error(err)
    }
}

const getCategoryDropdown = async () => {
    try {
        return await api.get("api/v1/category-dropdown")
    } catch (err) {
        console.error(err)
    }
}
const getEmployeeDropdown = async () => {
    try {
        return await api.get("api/v1/staff-dropdown")
    } catch (err) {
        console.error(err)
    }
}
const getProductDropdown = async () => {
    try {
        return await api.get("api/v1/product-dropdown")
    } catch (err) {
        console.error(err)
    }
}
const getTemplateDropdown = async () => {
    try {
        return await api.get("api/v1/template-dropdown")
    } catch (err) {
        console.error(err)
    }
}



const dropdownService = {
    getSiteDropdown,
    getLocationDropdown,
    getCategoryDropdown,
    getEmployeeDropdown,
    getProductDropdown,
    getTemplateDropdown

}

export default dropdownService;