import { Button, Col, Form, Input, message, Row, Select, Spin } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getLocationslice, updateLocationSlice } from '../../redux/state/locationSlice';

const EditLocation = ({ setEditModel, addLoader, editRecord, siteDropDownData }) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  useEffect(() => {
    form.setFieldsValue({
      name: editRecord.name,
      warehouseId: editRecord.warehouseId._id,
      id: editRecord._id
    })
  }, [editRecord])

  const handleEditLocation = async (record) => {
    try {
      dispatch(updateLocationSlice(record)).then((response) => {
        if (response.payload.success) {
          dispatch(getLocationslice())
          setEditModel(false)
          message.success("Location edited successfully")
        } else {
          message.error("Location not edited")
        }
      })
    }
    catch (err) {
      message.error(err, "Location site error")
    }
  }


  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form form={form} onFinish={handleEditLocation} layout="vertical" >
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Edit name</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Location name is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate name!",
                      },
                    ]}
                    name='name'>
                    <Input className='site_input_group' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='form_control'
                    name="warehouseId"
                    label={
                      <span className="product_lable">Edit site</span>
                    }>
                    <Select className='site_input_group'>
                      {siteDropDownData}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className='user_field'>
                  <Form.Item
                    name='id' >
                    <Input type={'hidden'} className='site_input_group' />
                  </Form.Item>
                </Col>
              </Row>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => setEditModel(false)}>
                  Cancel
                </Button>
                <Button className='update_site_btn' size="large" htmlType='submit'>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default EditLocation