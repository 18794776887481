import React, { useState, useEffect } from 'react'
import './job.css'
import { Button, Select, Spin, Form, message, Col, Row } from 'antd';
import Input from '../common/Index'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getProductDropSlice } from '../../redux/state/dropdownSlice';
import {  getJobslice, getSingleJoblice, updateJobSlice } from '../../redux/state/jobSlice';
import { isNumber } from 'lodash';
import {  PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditJob = ({ setEditModal, editRecord1, }) => {
  const [form] = Form.useForm();
  const [productData, setProductData] = useState([])
  const [editOldData, setEditOldData] = useState([])
  const [inputNumberValue, setInputNumberValue] = useState('')
  const [editRecord, setEditRecord] = useState([])
  const { id } = useParams();
  const dispatch = useDispatch()

  const { productdropdowns, isLoading: addLoader } = useSelector(state => state.sitedropdowns)
  const { productItems } = useSelector(state => state.jobs)


  useEffect(() => {
    dispatch(getSingleJoblice(editRecord1._id))
    dispatch(getProductDropSlice())
  }, [dispatch, id, editRecord1]);

  useEffect(() => {
    form.resetFields()
  }, [editOldData])

  useEffect(() => {
    if (productItems && productItems.data) {
      setEditRecord(productItems);
    }
    if (productdropdowns && productdropdowns.data) {
      const drpdown = productdropdowns.data.map((e) => {
        return { value: e._id, label: e.name }
      })
      setProductData(drpdown);
    }
  }, [productItems, productdropdowns])



  useEffect(() => {
    if (editRecord.data && editRecord.data.length > 0) {
      const oldjobcardProductItem = editRecord?.data.map((item, index) => {
        return {
          product_name: item.productId.name ? item.productId._id : "",
          comment: item.comment ? item.comment : "",
          quantity: item.qty ? item.qty : "1"
        }
      })
      setEditOldData(oldjobcardProductItem)
      form.resetFields();
    }
  }, [editRecord])

  const handleResetSite = async () => {
    try {
      setEditModal(false)
      form.resetFields()
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditJobcard = async (values) => {

    delete values.product_name;
    delete values.quantity;
    delete values.comment;
    delete values.jobCardNo;

    const { cardItem } = values;
    if (!Array.isArray(cardItem) || cardItem == undefined || cardItem.length === 0) {
      message.error("Add at least one product")
      return
    }
    try {
      values = { ...values, id: editRecord1._id }
      dispatch(updateJobSlice(values))
        .then((response) => {
          if (response.payload.success) {
            setEditModal(false)
            dispatch(getJobslice())
            message.success("Jobcard edited successfully")
            // form.resetFields()
          } else {
            message.error(response.payload.message)
          }
        }).catch((err) => {
          console.error(err)
        })
    }
    catch (err) {
      message.error(err, "add Location error")
    }
  }

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (isNumber(inputValue) && isNumber(inputValue) >= 1) {
      setInputNumberValue(inputValue)
    }
  };

  const onKeyPress = (e) => {
    let validCharCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    if (!validCharCodes.includes(e.charCode)) {
      e.preventDefault();
    }
  }

  return (
    <>
      {/* Job Discription  */}
      <Spin size='large' spinning={addLoader}>
        <Form layout='vertical' initialValues={{ jobCardNo: editRecord.chassisname }} form={form} name="dynamic_form_nest_item" onFinish={handleEditJobcard} autoComplete="off">
          <Form.Item
            className='form_control chassis_no'
            name="jobCardNo"
            label={
              <>
                <span className="product_lable_1">Chassis number : </span>
                <p className="site_input_group_1 edit_chassis_no"> {editRecord.chassisname}</p>
              </>
            }>
          </Form.Item>
          <div className="job_product_data">
            <Form.List name="cardItem" initialValue={editOldData}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <>
                        <Row gutter={10} key={field.key} className="add_product" >
                          <Col span={10}>
                            <Form.Item
                              className='form_control'
                              {...field}
                              name={[field.name, 'product_name']}
                              placeholder="Select Product Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Product name is required',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Product Name"
                                showSearch={true}
                                options={productData}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}

                              />

                            </Form.Item>
                          </Col>
                          <Col span={6} >
                            <Form.Item
                              className='form_control'
                              {...field}
                              name={[field.name, 'quantity']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Quantity is required',
                                },

                              ]}
                            >
                              <Input placeholder="Quantity" maxLength={6} inputtype="number" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              className='form_control'
                              {...field}
                              // label="Price"
                              name={[field.name, 'comment']}

                            >
                              <Input placeholder='Comment' />
                            </Form.Item>
                          </Col>
                          <Col span={2} className="remove_job">
                            <DeleteOutlined onClick={() => remove(field.name)} />

                          </Col>
                        </Row>
                      </>
                    )
                  }

                  )}

                  <Form.Item>
                    <Button type="dashed" className='add_new_product' onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Products
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          <Form.Item>
            <div className="third_row">
              <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>
                Cancel
              </Button>
              <Button className='update_site_btn' size="large" htmlType='submit'>
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  )
}

export default EditJob
