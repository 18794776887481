import { configureStore } from '@reduxjs/toolkit'
import siteReducer from './siteSlice';
import locationReducer from './locationSlice';
import categoryReducer from './categorySlice'
import productReducer from './productSlice'
import employeeReducer from './employeeSlice'
import dropdownReducer from './dropdownSlice';
import jobReducer from './jobSlice';
import loginReducer from './loginSlice';
import lowproductReducers from './lowProductSlice.js'
import dashboardReducer from './dashboardSlice'
import templateReducer from './templateSlice'
import reportReducer from './reportsSlice'
import filereaderReducer from './bulkdataSlice'

export const store = configureStore({
    reducer: {
        site: siteReducer,
        locations: locationReducer,
        categorys: categoryReducer,
        products: productReducer,
        employees: employeeReducer,
        sitedropdowns: dropdownReducer,
        jobs: jobReducer,
        logins: loginReducer,
        lowproduts: lowproductReducers,
        dashboard: dashboardReducer,
        templates: templateReducer,
        reports: reportReducer,
        filedata: filereaderReducer
    },
})

export default store;
