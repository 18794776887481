import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Popconfirm, Spin } from 'antd';
import '../sites/sites.css'
import { Table, Button, Modal, message, Form } from 'antd';
import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeslice, addEmployeeSlice, deleteEmployeeSlice } from '../../redux/state/employeeSlice';
import AddEmployee from './addEmployee';
import { PlusOutlined } from '@ant-design/icons';
import Editemployee from './editEmployee';




const Employee = () => {

  const dispatch = useDispatch()
  const [myEmployeeData, setMyEmployeeData] = useState([])
  const [status, setStatus] = useState(true)
  const [open, setOpen] = useState(false)
  const [myForm] = Form.useForm()
  const [editModel, setEditModel] = useState(false);
  const [editRecord, setEditRecord] = useState({})


  useEffect(() => {
    dispatch(getEmployeeslice())
  }, [dispatch]);

  const { employees, isLoading: addLoader } = useSelector(state => state.employees)

  // useEffect(() => {
  //   let ele = document.querySelector('.ant-table-measure-row').remove()
  // }, [])

  useEffect(() => {
    if (employees && employees.data) {
      const dd = employees.data.map((e) => {
        return { ...e, key: e._id }
      })
      setMyEmployeeData(dd);
    }
  }, [employees])

  const handleAddEmployee = (values) => {
    values = { ...values, is_active: status }
    dispatch(addEmployeeSlice(values)).then((response) => {
      if (response.payload.success) {
        message.success("Employee added successfully")
        dispatch(getEmployeeslice())
        setOpen(false)
        myForm.resetFields()
      } else {
        message.error(response.payload.message)
      }
    }).catch((err) => {
      console.error(err)
    })
  }


  //  Location delete 
  const handleDeleteEmployee = (id) => {
    try {
      dispatch(deleteEmployeeSlice(id)).then((response) => {
        if (response.payload.success) {
          if (response.payload.isdelete) {
            dispatch(getEmployeeslice())
            message.success("Employee deleted successfully")
          } else {
            message.error(`Can't delete this record as other items are associated with it.`)
          }
        } else {
          message.error(response.payload.message)
        }
      })
    }
    catch (error) {
      message.error(error, "error delete time")
    }
  }

  const handleResetSite = async () => {
    try {
      myForm.resetFields()
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }
  // Edit Employee Module 
  const handleEditShow = (record, id) => {
    setEditModel(true)
    setEditRecord(record)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record, id) => {
        return (
          <>
            {id + 1}
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record) => {
        return (
          <>
            {record.name}
          </>
        )
      }
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const text = 'Are you sure to delete this Item ?';

        return (
          <>
            <span>
              <Button className="btn_edit mr_1" onClick={() => handleEditShow(record, record._id)} icon={<EditIcon />} />
              <Popconfirm placement="topRight" title={text} onConfirm={() => handleDeleteEmployee(record._id)} okText="Yes" cancelText="No" >
                <Button className="btn_delete" icon={<DeleteIcon />} />
              </Popconfirm>
            </span>
          </>
        )
      }
    }

  ];


  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <div className='main_wrapper'>
          <div className="site_header">
            <label className='content_heading'><h2>Employee Details</h2></label>
            <Button className='add_btn' size="large" onClick={() => setOpen(true)}>
              <span><PlusOutlined /></span>  Add New</Button>
          </div>

          {/* Site Table  */}
          {/* <h4>Employee Table</h4> */}
          <Table columns={columns} dataSource={myEmployeeData} bordered sticky={true} size="middle" className='tabel_height main_table' />
          <Outlet />
        </div>
      </Spin>




      <Modal maskClosable={false} title="Add new employee" footer={null} className='site_title' open={open} onCancel={() => handleResetSite()}>
        <AddEmployee addLoader={addLoader} myForm={myForm} setOpen={setOpen} handleResetSite={handleResetSite} handleAddEmployee={handleAddEmployee} status={status} setStatus={setStatus} />
      </Modal>

      <Modal open={editModel} maskClosable={false} title="Edit employee" width={550} onCancel={() => setEditModel(false)} className='site_title' footer={null} >
        <Editemployee editRecord={editRecord} setEditModel={setEditModel} addLoader={addLoader} />
      </Modal>
    </>
  )
}

export default Employee