import React, { useState, useEffect } from 'react'
import { Button, Select, Spin, Form, message, Row, Col } from 'antd';
import Input from '../common/Index'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getProductDropSlice } from '../../redux/state/dropdownSlice';
import { getJobslice } from '../../redux/state/jobSlice';
import {  PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { isNumber, toLower } from 'lodash';
import { addTemplateslice, getTemplateslice } from '../../redux/state/templateSlice';

const { Option } = Select;



const AddTemplate = ({ setAddModel }) => {
    const [form] = Form.useForm();
    const [inputNumberValue, setInputNumberValue] = useState('')
    const [productData, setProductData] = useState([])
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { employeedropdowns, isLoading: addLoader } = useSelector(data1 => data1.sitedropdowns)
    const { productdropdowns } = useSelector(data1 => data1.sitedropdowns)


    useEffect(() => {
        dispatch(getJobslice())
        dispatch(getProductDropSlice())
    }, [dispatch]);


    useEffect(() => {
        if (productdropdowns && productdropdowns.data) {
            const drpdown = productdropdowns.data.map((e) => {
                return { value: e._id, label: e.name }
            })
            setProductData(drpdown);
        }
    }, [productdropdowns, employeedropdowns])


    const handleResetSite = async () => {
        try {
            setAddModel(false)
            setData([])
            form.resetFields()

        } catch (error) {
            console.error(error)
        }
    }



    // ADD Template data 
    const handleAddTemp = (values) => {

        const { templateName, templateItem } = values;
        if (!Array.isArray(templateItem) || templateItem == undefined || templateItem.length === 0) {
            message.error("Add at least one product")
            return
        }
        else if (values.templateName.trim().length === 0) {
            message.error("Chassis number required")
            return
        }
        try {
            dispatch(addTemplateslice(values))
                .then((response) => {
                    if (response.payload.success === true && response.payload.data) {
                        form.resetFields()
                        setData([])
                        setAddModel(false)
                        dispatch(getTemplateslice())
                        navigate("/template", { replace: true })
                        message.success("Template added successfully")
                    } else {
                        message.error(response.payload.message)
                    }
                }).catch((err) => {
                    console.error(err)
                })
        }
        catch (err) {
            message.error(err, "add Location error")
        }

    }

    const handleChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;

        if (isNumber(inputValue)) {
            setInputNumberValue(inputValue)
        }
    };

    const onKeyPress = (e) => {
        let validCharCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
        if (!validCharCodes.includes(e.charCode)) {
            e.preventDefault();
        }
    }



    return (
        <>
            {/* Job Discription  */}
            <Spin size='large' spinning={addLoader}>
                <Form layout='vertical ' form={form} name="dynamic_form_nest_item" onFinish={handleAddTemp} autoComplete="off">
                    <Form.Item
                        className='form_control'
                        name="templateName"
                        label={
                            <>
                                <span className="product_lable">Template name : </span>
                            </>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Template name is required',
                            },
                        ]}
                    >
                        <Input maxLength={80} inputtype='' />

                    </Form.Item>
                    <div className="">
                        <Form.List name="templateItem">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (

                                        <>
                                            {
                                                form.getFieldValue('templateItem').map((element, index) => {
                                                    if (element !== undefined && element.product_name !== undefined) {
                                                    }
                                                })
                                            }
                                            <Row gutter={10} className="add_product" >
                                                <Col span={10}>
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        name={[field.name, 'product_name']}
                                                        placeholder="Select Product Name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Product name is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Select Product Name"
                                                            showSearch
                                                            options={productData}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} >
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        name={[field.name, 'quantity']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Quantity is required',
                                                            }
                                                        ]}
                                                    >
                                                        <Input placeholder="Quantity" maxLength={6} inputtype="number" handlechange={handleChange} onKeyPress={onKeyPress} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className='form_control'
                                                        {...field}
                                                        // label="Price"
                                                        name={[field.name, 'comment']}

                                                    >
                                                        <Input placeholder='Comment' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} className="remove_job">
                                                    <DeleteOutlined onClick={() => remove(field.name)} />

                                                </Col>
                                            </Row>
                                        </>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" className='add_new_product' onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Products
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                    <Form.Item>
                        <div className="third_row">
                            <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>
                                Cancel
                            </Button>
                            <Button className='add_site_btn' size="large" htmlType='submit'>
                                Save
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    )
}

export default AddTemplate
