import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, message, Row, Select, Spin, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import Input from '../common/Index/index'
import { useDispatch } from 'react-redux';
import { getProductslice, updateProductSlice } from '../../redux/state/productSlice';
import { values } from 'lodash';



const getBase64 = async (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (error) {
    cb(error);
  };
};



const EditProduct = ({ setEditModel, addLoader, change, editRecord, locationdropdown, categorydropdown, setchange }) => {
  const [form] = Form.useForm();
  const [inputNumberValue, setInputNumberValue] = useState('')
  const [fileList, setFileList] = useState([]);
  const [id, setId] = useState([])
  const [islocation, setIslocation] = useState(0)

  const values = () => {
    setchange(false)
    return editRecord?.categoryId?.name
  }

  const dispatch = useDispatch()
  useEffect(() => {
    form.setFieldsValue({
      name: editRecord.name,
      sku: editRecord.sku,
      price: editRecord.price,
      categoryId: editRecord.categoryId ? editRecord.categoryId._id : "",
      locationId: editRecord.locationId ? editRecord.locationId._id : values(),
      lowQty: editRecord.lowQty ? editRecord.lowQty : 0,
      supplier: editRecord.supplier,
      legacySKU: editRecord.legacySKU,
      lastPrice: editRecord.lastPrice ? editRecord.lastPrice : 0,
      description: editRecord.description,
      image: editRecord.image ? editRecord.image : "",
      id: editRecord._id,
      received_as: editRecord.received_as ? editRecord.received_as : "",
      picked_as: editRecord.picked_as ? editRecord.picked_as : "",
      supplierBarcode: editRecord.supplierBarcode ? editRecord.supplierBarcode : ""
    })
    setId(editRecord._id)
    setFileList([{
      name: fileName(editRecord.image),
      url: editRecord.image,
    },])
  }, [editRecord])


  const EditExp = async (dd) => {
    dd.id = id
    dd.lowQty = (dd.lowQty == "" ? 0 : dd.lowQty)
    dd.lastPrice = (dd.lastPrice == "" ? 0 : dd.lastPrice)
    // return false;
    if (!change) {
      delete dd.locationId
      if (dd?.image?.startsWith("data:image")) {
        try {

          dispatch(updateProductSlice(dd)).then((response) => {
            if (response.payload.success) {
              dispatch(getProductslice())
              setEditModel(false)
              message.success("Product edited successfully")
            } else {
              message.error(response?.payload?.message)
            }
          })
        }
        catch (err) {
          message.error(err, "Edit product error")
        }
      } else {

        try {
          dispatch(updateProductSlice(dd)).then((response) => {
            if (response.payload.success) {
              dispatch(getProductslice())
              setEditModel(false)
              message.success("Product edited successfully")
            } else {
              message.error(response?.payload?.message)
            }
          })
        }
        catch (err) {
          message.error(err, "Edit product error")
        }
      }
    } else {
      if (dd?.image?.startsWith("data:image")) {
        try {
          dispatch(updateProductSlice(dd)).then((response) => {
            if (response.payload.success) {
              dispatch(getProductslice())
              setEditModel(false)
              message.success("Product edited successfully")
            } else {
              message.error(response.payload.message || "Something went wrong!")
            }
          })
        }
        catch (err) {
          message.error(err, "Edit product error")
        }
      } else {
        try {
          dispatch(updateProductSlice(dd)).then((response) => {
            if (response.payload.success) {
              dispatch(getProductslice())
              setEditModel(false)
              message.success("Product edited successfully")
            } else {
              message.error(response.payload.message || "Something went wrong!")
            }
          })
        }
        catch (err) {
          message.error(err, "Edit product error")
        }
      }
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return false;
  };

  const fileName = (ff = null) => {
    if (ff !== null) {
      const arr = ff.split("/");
      return arr[arr.length - 1];
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  }

  const handleRemove = (fl) => {
    setFileList([]);

  };


  const handleEditProduct = (values) => {
    values = { ...values }
    let sendData = values;
    if (values?.image?.file?.name != undefined) {
      if (values?.image?.file?.status == "removed") {
        sendData.image = "";
        EditExp(sendData)
      } else {
        getBase64(fileList[0]?.originFileObj, (result) => {
          if (result?.startsWith("data:image")) {
            sendData.image = result;
            EditExp(sendData)
          } else {
            beforeUpload()
          }
        }
        )
      }

    }
    else {
      sendData.image = "";
      EditExp(sendData)
    }

  }


  const formatNumber = value => {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const parseNumber = value => {
    if (value) {
      return value.toString().replace(/,/g, '');
    }
    return undefined;
  };



  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form layout="vertical" form={form} onFinish={handleEditProduct} >
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Product name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Product name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='name'>
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">SKU</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "SKU id is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='sku' >
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control form_prefix'
                      label={
                        <span className="product_lable">Price (Inc.GST)</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Price is valid required",
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(rule, value) {
                        //     if (!value || getFieldValue("price") > 0) {
                        //       return Promise.resolve();
                        //     }
                        //     return Promise.reject("Price is invalid");
                        //   },
                        // })
                      ]}
                      name="price">
                      <InputNumber prefix="$" formatter={formatNumber}
                        parser={parseNumber} inputtype="number" allowdecimal={true} className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>

              </div>

              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Category</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select category",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },

                      ]}
                      name='categoryId' >
                      <Select className='site_input_group'>
                        {categorydropdown}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Location</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please select location",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='locationId' >
                      <Select className='site_input_group' onChange={() => setchange(true)}>
                        {locationdropdown}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Low stock</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Low stock is required",
                        }
                      ]}
                      name='lowQty' >
                      <Input inputtype='number' min='0' max="100000" className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="first_product_row">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Supplier name</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Supplier name is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name="supplier" >
                      <Input className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Legacy SKU</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "LegacySKU is required",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate legacySKU!",
                        },
                      ]}
                      name='legacySKU'>
                      <Input type='text' className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control form_prefix'
                      label={
                        <span className="product_lable">Last price</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Last price is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("lastPrice") > 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Last price is invalid");
                          },
                        })
                      ]}
                      name="lastPrice">
                      <InputNumber prefix="$" formatter={formatNumber}
                        parser={parseNumber} inputtype="number" allowdecimal={true} className='site_input_group' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className='first_product_row'>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Received as</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Recived as is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='received_as'>
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Picked as</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Picked as is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='picked_as'>
                      <Input maxLength={80} className='site_input_group' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Supplier barcode</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Supplier barcode is requied",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        }
                      ]}
                      name='supplierBarcode'>
                      <Input maxLength={80} className='site_input_group' inputtype="alphanumnospace" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="second_row">
                <Row gutter={24}>
                  <Col span={16}>
                    <Form.Item
                      className='form_control'
                      label={
                        <span className="product_lable">Product description</span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please input Descrip..",
                        },
                        {
                          type: "text",
                          message: "Please enter a validate name!",
                        },
                      ]}
                      name='description' >
                      <TextArea
                        autoSize={{
                          minRows: 2,
                          maxRows: 6,

                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="image"
                    >
                      <Upload
                        listType="picture"
                        accept='.png, .jpg'
                        defaultFileList={[...fileList]}
                        className="image_lists"
                        maxCount={1}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        onRemove={handleRemove}

                        fileList={fileList}
                      >
                        <span> <Button icon={<UploadOutlined />}>Upload</Button> <p>(Upload only jpg/png file)</p></span>

                      </Upload>
                    </Form.Item>
                  </Col>
                  {/* <Col span={1} className='user_field'>
                    <Form.Item name='id' > <Input type={'hidden'} className='site_input_group' /> </Form.Item>
                  </Col> */}
                </Row>
              </div>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => setEditModel(false)}>Cancel</Button>
                <Button className='update_site_btn' size="large" htmlType='submit'>Update</Button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default EditProduct