import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import siteService from '../api/siteApi'

const initialState = {
    sites: [],
    isLoading: false,
    error: null
}

export const getSiteslice = createAsyncThunk('sites/retrive', async () => {
    try {
        const response = await siteService.getAllSite()
        return response.data
    } catch (error) {
        console.log(error)
    }
})
export const addSiteSlice = createAsyncThunk('site/create', async (data) => {
    try {
        const response = await siteService.addSite(data)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const deleteSiteSlice = createAsyncThunk('site/delete', async (data) => {
    try {
        const response = await siteService.deleteSiteById(data)
        return response.data
    } catch (error) {
        console.log(error)
    }
})


export const updateSiteSlice = createAsyncThunk('site/retriveByid', async (data, id) => {
    try {
        const response = await siteService.updateSiteById(data, data.id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {},
    extraReducers: {
        [getSiteslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getSiteslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.sites = action.payload
        },
        [getSiteslice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        },
        [addSiteSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [addSiteSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.sites = [action.payload]
        },
        [addSiteSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
        ,
        [deleteSiteSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteSiteSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.sites = [action.payload]
        },
        [deleteSiteSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
        ,

        [updateSiteSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateSiteSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.sites = [action.payload]
        },
        [updateSiteSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.error = [action.payload]
        }
    }
});

export default siteSlice.reducer

