export const Product = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M3.27002 6.95898L12 12.009L20.73 6.95898" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M12 22.08V12" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
    </svg>
);
export const Category = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 3H3V10H10V3Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M21 3H14V10H21V3Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M21 14H14V21H21V14Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M10 14H3V21H10V14Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
    </svg>
);
export const Employee = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M23 21.0009V19.0009C22.9993 18.1146 22.7044 17.2537 22.1614 16.5532C21.6184 15.8527 20.8581 15.3525 20 15.1309" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M16 3.12891C16.8604 3.34921 17.623 3.84961 18.1676 4.55122C18.7122 5.25283 19.0078 6.11574 19.0078 7.00391C19.0078 7.89208 18.7122 8.75499 18.1676 9.4566C17.623 10.1582 16.8604 10.6586 16 10.8789" stroke="white" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
    </svg>
);