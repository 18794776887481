import React from 'react'
import { Col, Row, Spin } from 'antd';
import '../sites/sites.css'
import { Button, Input, Form } from 'antd';



const AddEmployee = ({ myForm, addLoader, handleResetSite, handleAddEmployee }) => {

  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <Form layout="vertical" form={myForm} onFinish={handleAddEmployee}>
          <div className='model_wrapper_main'>
            <div className='site_form'>
              <Row gutter={16}>
                <Col span={24} className='user_field'>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Employee name</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Employee name is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate name!",
                      },
                    ]}
                    name="name"
                  >
                    <Input maxLength={80} className='site_input_group_employee' />
                  </Form.Item>
                </Col>
                <Col span={24} className='user_field'>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">User name</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "User name is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate !",
                      },
                    ]}
                    name="username"
                  >
                    <Input maxLength={80} className='site_input_group_employee' />
                  </Form.Item>
                </Col>
                <Col span={24} className='user_field'>
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable">Password</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        type: "text",
                        message: "Please enter a validate name!",
                      },
                    ]}
                    name="password"
                  >
                    <Input type='password' className='site_input_group_employee' />
                  </Form.Item>
                </Col>
              </Row>

              <div className="third_row">
                <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>Cancel</Button>
                <Button className='add_site_btn' size="large" htmlType='submit'>Save</Button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default AddEmployee