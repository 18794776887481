import React, { Component } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
// import './input.less';

class CustomInput extends Component {
  onChange = (e) => {
    const { onChange, inputtype, maxamount, makecaps, allowdecimal } = this.props;
    let { value } = e.target;

    const { negitiveNumber = true } = this.props;
    if (!negitiveNumber) {
      value = value.replace('-', '');
    }

    if (inputtype === 'number' && value) {
      if (allowdecimal) {
        value =
          value.match(/^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm) ===
            null
            ? value.slice(0, value.length)
            : value.match(/^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm);
        value =
          value !== null
            ? Array.isArray(value)
              ? value.join('')
              : value.slice(0, value.length - 1)
            : value.slice(0, value.length - 1);
      } else {
        value = value.match(/\d/g);
        value = value && value.join('');
      }
    } else if (inputtype === 'alphanumeric' && value) {
      value = value.match('^[A-Za-z0-9]+$');
      value = value && value.join('');
    } else if (inputtype === 'alphanumspace' && value) {
      value = value.match('^[A-Za-z0-9? ,_-]+$');
      value = value && value.join('');
    } else if (inputtype === 'alphanumnospace' && value) {
      value = value.match('^[A-Za-z0-9?,_-]+$');
      value = value && value.join('');
    }

    if (parseInt(value) > maxamount) {
      value = value && value.slice(0, value.length - 1);
    }

    if (makecaps === 'true') {
      value = value.toUpperCase();
    }
    onChange(value);
  };

  render() {
    const propsCopy = { ...this.props };
    delete propsCopy.negitiveNumber;
    delete propsCopy.inputtype;
    return (
      <Input className="custom-input" {...propsCopy} onChange={this.onChange} />
    );
  }
}
CustomInput.propTypes = {
  inputtype: PropTypes.string,
  negitiveNumber: PropTypes.bool,
  onChange: PropTypes.func,
};
CustomInput.defaultProps = {
  inputtype: 'string',
  negitiveNumber: true,
  onChange: () => { },
};
export default CustomInput;
CustomInput.TextArea = Input.TextArea;
CustomInput.Password = Input.Password;
CustomInput.Search = Input.Search;
