
import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table } from 'antd'
// import ColumnGroup from 'antd/lib/table/ColumnGroup'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getreportJobslice, getSinglejobcardSlice } from '../../redux/state/reportsSlice'
import Highlighter from 'react-highlight-words';

const { Option } = Select
const { RangePicker } = DatePicker;


const Reports = () => {

  const customFormat = value => `${value.format("DD-MM-YYYY")}`;
  const initialStartDate = moment(moment().startOf('month')).format("DD-MM-YYYY")
  const initialEndDate = moment(new Date()).format("DD-MM-YYYY")

  const [status, setStatus] = useState("pending")
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [viewjobData, setViewJobData] = useState(false)
  const [jobcardId, setJobcardId] = useState()
  const [page, setPage] = useState(1);


  const [jobcardData, setJobcardData] = useState([])
  const [jobproductData, setJobproductData] = useState([])
  const [jobproductDataArray, setJobproductDataArray] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);



  const navigate = useNavigate()
  const dispatch = useDispatch()


  // TABLE SEARCHING START 
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    console.log("first")
    setSearchText('');
    clearFilters();
    close();
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && confirm && handleReset(clearFilters, close, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  // TABLE SEARCHING END 




  const ViewJobcardRecord = (record) => {
    setJobcardId(record._id)
    setViewJobData(true)
  }
  const handleCancel = () => {
    setViewJobData(false)
  }

  useEffect(() => {
    dispatch(getreportJobslice({ status, startDate, endDate }))
    dispatch(getSinglejobcardSlice(jobcardId))
  }, [dispatch, status, startDate, endDate, jobcardId]);


  const { reports } = useSelector(state => state.reports)
  const { jobcardDatas } = useSelector(state => state.reports)


  useEffect(() => {
    setJobcardData([])
    if (reports[0]?.success && reports[0]?.data) {
      setJobcardData(reports[0]?.data)
    }
    if (jobcardDatas[0]?.success && jobcardDatas[0]?.data) {
      setJobproductData(jobcardDatas[0]?.data)
      setJobproductDataArray(jobcardDatas[0]?.data.productDetails)
    }


  }, [reports, jobcardDatas])

  const Productcolumns = [
    {
      title: 'No',
      dataIndex: '',
      render: (_, record, i) => {
        return (
          <>
            {(page - 1) * 10 + (i + 1)}
          </>
        )
      }
    },
    {
      title: 'Product name',
      dataIndex: 'productName',
    },
    {
      title: 'Price',
      render: (_, record, id) => {
        return (
          <>
            {record.price ? `$ ${record.price}` : "-"}
          </>
        )
      }

    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
    }
    ,
    {
      title: 'Total',
      render: (_, record, id) => {
        return (
          <>
            {record.total ? `$ ${record.total.toFixed(2)}` : "-"}
          </>
        )
      }

    }
  ];


  const jobscolumns = [
    {
      title: 'No',
      dataIndex: '',
      render: (_, record, i) => {
        return (
          <>
            {(page - 1) * 10 + (i + 1)}
          </>
        )
      }
    },
    {
      title: 'Jobcard name',
      dataIndex: 'jobCardNo',
      ...getColumnSearchProps('jobCardNo', 'Jobcard name'),
      sorter: (a, b) => a.jobCardNo.localeCompare(b.jobCardNo),
      ellipsis: true,
    },
    {
      title: 'Start date',
      dataIndex: 'start_date',
      render: (_, record) => {
        return (
          <>
            {record.start_date ? moment(record.start_date).format("DD-MM-YYYY") : "-"}
          </>
        )

      }
    },
    {
      title: 'End date',
      dataIndex: 'end_date',
      render: (_, record) => {
        return (
          <>
            {record.end_date ? moment(record.end_date).format("DD-MM-YYYY") : "-"}
          </>
        )

      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (_, record) => {
        return (
          <>
            {record.duration ? record.duration + "day" : "-"}
          </>
        )

      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {

        return (
          <>
            {record.status == "completed" ? <span className='completed_task'>Completed</span> :
              <span >{record.status === 'inprocess' ? <span className='inprocess_task'>In progress</span> : <span className='pending_task'>Pending</span>}</span>}
          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {

        return (
          <>
            <Button className="btn_view mr_1" onClick={() => ViewJobcardRecord(record)} icon={<EyeOutlined />} />

          </>
        )
      }
    }
  ];

  return (
    <>
      <div className="report_wrapper">
        <Card className='job_card_wrapper' title="Reports"
          extra={
            <div className='form_control job_dropdown '>
              <RangePicker format={customFormat} onChange={(e) => {
                const StartDate = e[0].format('DD-MM-YYYY')
                const EndDate = e[1].format('DD-MM-YYYY')
                setStartDate(StartDate)
                setEndDate(EndDate)
              }
              }
                defaultValue={[
                  moment(moment().startOf('month'), customFormat),
                  moment(new Date(), customFormat)
                ]}
              />
              <Select
                showSearch
                placeholder="Status"
                className='jobcard_items'
                defaultValue="pending"
                optionFilterProp="children"
                onChange={(value) => setStatus(value)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

              >
                <Option value="pending">Pending</Option>
                <Option value="inprocess">In progress</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </div>
          }
        >
          <Table columns={jobscolumns} bordered dataSource={jobcardData} sticky={true} size="middle" pagination={{ onChange(current) { setPage(current); } }} className='tabel_height_1 main_table' />

          {/* View jobard Modal  */}
          <Modal title="View job details" footer={null} open={viewjobData} width={700} onCancel={handleCancel} >
            <div className="model_lable_1 ">
              <Row gutter={12}>
                <Col span={16} className="product_lable_view" >
                  <Form.Item
                    className='form_control'
                    label={
                      <span className="product_lable_1">Jobcard name</span>
                    }>
                    <p className="site_input_group_1">{jobproductData.jobcardName}</p>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="product_data">
              <Table dataSource={jobproductDataArray} bordered columns={Productcolumns} pagination={false} />
            </div>
            <Form.Item className='grand_total' >
              <span className="product_lable_1 grand_title">Grand total :</span>
              <p className="site_input_group_1 grand_text">$ {jobproductData.grandTotal ? jobproductData.grandTotal.toFixed(2) : 0}</p>
            </Form.Item>
          </Modal>


        </Card>
      </div>
    </>
  )
}

export default Reports