import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { message, Spin, Form } from 'antd';
import '../sites/sites.css'
import { Table, Button, Modal } from 'antd';
import { EditIcon } from '../../assets/icons/EditIcon';
import { useDispatch, useSelector } from "react-redux";
import { getSiteslice } from '../../redux/state/siteSlice';

import Editsite from './addSite/editsite';

const Site = () => {
  const [myData, setMyData] = useState([])
  const [editModel, setEditModel] = useState(false);
  const [editRecord, setEditRecord] = useState({})
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const [myForm] = Form.useForm();
  const { sites, isLoading: addLoader } = useSelector(state => state.site)

  useEffect(() => {
    dispatch(getSiteslice())
  }, [dispatch]);

  useEffect(() => {
    if (sites && sites.data) {
      const dd = sites.data.map((e) => {
        return { ...e, key: e._id }
      })
      setMyData(dd);
    }
  }, [sites])

  // add site Module 
  // const handleAddSite = async (values) => {
  //   try {
  //     values = { ...values, is_active: status }
  //     dispatch(addSiteSlice(values)).then((response) => {
  //       if (response.payload.success) {
  //         message.success("Site added successfully")
  //         dispatch(getSiteslice())
  //         myForm.resetFields()
  //         setAddModel(false)
  //       } else {
  //         message.error(response.payload.message)
  //       }
  //     }).catch((error) => {
  //       message.error(error)
  //     })
  //   }
  //   catch (err) {
  //     message.error(err, "add site error")
  //   }
  // }

  // delete Site module 
  // const handleDeleteSite = (id) => {
  //   try {
  //     dispatch(deleteSiteSlice(id)).then((response) => {
  //       if (response.payload.success) {
  //         if(response.payload.isdelete){
  //           dispatch(getSiteslice())
  //           message.success("Site deleted successfully")
  //         }else{
  //           message.error(`Can't delete this record as other items are associated with it.`)
  //         }
  //       } else {
  //         message.error(response.payload.message)
  //       }
  //     })
  //   }
  //   catch (error) {
  //     message.error(error, "error delete time")
  //   }
  // }


  // Edit site Module 
  const handleEditShow = (record, id) => {
    setEditModel(true)
    setEditRecord(record)
  }

  const handleResetSite = async () => {
    try {
      myForm.resetFields()
      setEditModel(false)
    } catch (error) {
      message.error(error)
    }
  }
  // Data Render 
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record, id) => {
        return (
          <>
            {(page - 1) * 10 + (id + 1)}
            {/* {id + 1} */}
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'location',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <>
            <span>
              <Button className="btn_edit" onClick={() => handleEditShow(record, record._id)} icon={<EditIcon />} />
              {/* <Popconfirm placement="topRight" title={text}  onConfirm={() => handleDeleteSite(record._id)} okText="Yes"  cancelText="No" >
              <Button className='icon_btn' ><DeleteIcon className='delete_icon' type="primary" /></Button></Popconfirm> */}
            </span>
          </>
        )
      }
    },
  ];


  return (
    <>
      <Spin size='large' spinning={addLoader}>
        <div className='main_wrapper'>
          <div className="site_header">
            <label className='content_heading'><h2>Site Details</h2></label>


            {/* site Discription  */}


            <Modal open={editModel} maskClosable={false} title="Site" width={550} onCancel={() => handleResetSite()} className='site_title' footer={null} >
              <Editsite editRecord={editRecord} setEditModel={setEditModel} addLoader={addLoader} />
            </Modal>
          </div>

          {/* Site Table  */}

          <Table columns={columns} dataSource={myData} bordered sticky={true} size="middle" pagination={{ onChange(current) { setPage(current); } }} className='tabel_height' />
          <Outlet />
        </div>
      </Spin>
    </>
  )
}
export default Site


