import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import productService from '../api/productApi'

const initialState = {
    products: [],
    isLoading: false,
    error: null
}

export const getProductslice = createAsyncThunk('products/retrive', async () => {
    try {
        const response = await productService.getAllProduct()
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const addProductSlice = createAsyncThunk('product/create', async (data) => {
    try {
        const response = await productService.addProduct(data)
        return response.data ? response.data : response
    } catch (error) {
        console.log(error)
    }
})

export const deleteProductSlice = createAsyncThunk('product/delete', async (data) => {
    try {
        const response = await productService.deleteProductById(data)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const updateProductSlice = createAsyncThunk('product/retriveByid', async (data, id) => {
    try {
        const response = await productService.updateProductById(data, data.id)
        return response.data
    } catch (error) {
        console.log(error)
    }
})



const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: {
        [getProductslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [getProductslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.products = action.payload
        },
        [getProductslice.rejected]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [addProductSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [addProductSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [addProductSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [deleteProductSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteProductSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [deleteProductSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [updateProductSlice.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateProductSlice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        },
        [updateProductSlice.rejected]: (state, action) => {
            state.isLoading = false
            state.products = [action.payload]
        }
    }
});

export default productSlice.reducer

