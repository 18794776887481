

export const DeleteIcon = () => {
    return (
        <>

            <svg width="17" height="17" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.375 6.75H5.625H23.625" stroke="#FE0707" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 6.75V4.5C9 3.90326 9.23705 3.33097 9.65901 2.90901C10.081 2.48705 10.6533 2.25 11.25 2.25H15.75C16.3467 2.25 16.919 2.48705 17.341 2.90901C17.7629 3.33097 18 3.90326 18 4.5V6.75M21.375 6.75V22.5C21.375 23.0967 21.1379 23.669 20.716 24.091C20.294 24.5129 19.7217 24.75 19.125 24.75H7.875C7.27826 24.75 6.70597 24.5129 6.28401 24.091C5.86205 23.669 5.625 23.0967 5.625 22.5V6.75H21.375Z" stroke="#FE0707" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.25 12.375V19.125" stroke="#FE0707" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.75 12.375V19.125" stroke="#FE0707" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}