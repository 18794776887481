const errorFunction = async(statusCode,error)=>{
   if(statusCode === 409){
    return {success: false, message:error.response.data ? error.response.data.message :"Somthing is wrong."}
   } else if(statusCode === 401){
    return {success: false,  message:error.response.data ? error.response.data.message :"Somthing is wrong."}
   } else if(statusCode === 406){
     return {success: false, message: error.response.data ? error.response.data.message :"Somthing is wrong."}
   } else if(statusCode === 412){
      return {success: false,  message: error.response.data ? error.response.data.message :"Somthing is wrong."}
   }
}

export default errorFunction