import { api } from './index';
import setAuthToken from "./headers"
import errorFunction from './error';

const getTemplateData = async () => {
    try {
        return await api.get("api/v1/template", { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}
const getTemplateDataById = async (id) => {
    try {
        return await api.get(`api/v1/template/${id}`, { headers: setAuthToken() })
    } catch (err) {
        console.log(err)
    }
}
const addTemplateData = async (data) => {
    try {
        return await api.post("api/v1/template", data, { headers: setAuthToken() })
    } catch (err) {
        return errorFunction(err.response.status, err)
    }
}
const getsingleTemplateById = async (id) => {
    return await api.get(`api/v1/template/${id}`, { headers: setAuthToken() })
}

const updateTemplateById = async (data, id) => {
    try {
        return await api.put(`api/v1/template/${id}`, data, { headers: setAuthToken() })
    } catch (err) {
        return errorFunction(err.response.status, err)
    }
}
const deleteTemplateById = async (id) => {
    return await api.delete(`api/v1/template/${id}`, { headers: setAuthToken() })
}

const templateService = {
    getTemplateData,
    getTemplateDataById,
    addTemplateData,
    updateTemplateById,
    getsingleTemplateById,
    deleteTemplateById
}

export default templateService;