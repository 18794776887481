import React, { useEffect, useState } from 'react'
import { Button, Card, Col, List, Menu, Row, Select, Spin, Tabs } from 'antd';

import './dashbord.css'
import { getDashboardSlice } from '../../redux/state/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Category, Product, Employee } from '../../assets/icons/Dashbord';
import moment from 'moment';



const { Option } = Select;


const DashbordContent = () => {

  const dispatch = useDispatch()
  const [loaderHeight, setLoaderHeight] = useState(0);
  const [jobStatus, setJobStatus] = useState("pending")
  const [month, setMonth] = useState(1);
  const [dashboardData, setDashboardData] = useState({})
  const [jobcardData, setJobcardData] = useState([])
  const [productData, setProductData] = useState([])

  useEffect(() => {
    setLoaderHeight(document.getElementById('myCard').clientHeight / 2 || 0)
  }, [])

  useEffect(() => {
    dispatch(getDashboardSlice({ jobStatus, month }))
  },[jobStatus, month]);

  const { dashboard, isLoading: addLoader } = useSelector(state => state.dashboard)


  useEffect(() => {
    if (dashboard && dashboard.data) {

      setDashboardData(dashboard);
      setJobcardData(dashboard.data.jobDetails)
      setProductData(dashboard.data.lowProduct)

    }
  }, [dashboard])

  const onChange = (value) => {
    setMonth(value)
  };

  const onSearch = (value) => {
  };

  const menu = (
    <Menu
      items={[
        {
          label: <a href="#!">One month</a>,
          key: '0',
        },
        {
          label: <a href="#!">Three month</a>,
          key: '1',
        },
        {
          label: <a href="#!">Six month</a>,
          key: '3',
        },
      ]}
    />
  );

  return (
    <>
      <div className='dashboard_wrapper'>
        <Row gutter={16}>
          <Col span={14}>
            <Row gutter={16} className="mb_30">
              <Col span={8}>
                <Card className='widgets_card'>
                  <div className='widgets_card_inner'>
                    <div className='widgets_icon'>
                      <Product />
                    </div>
                    <div>
                      <h2>{dashboard?.data?.product}</h2>
                      <p>Product</p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card className='widgets_card'>
                  <div className='widgets_card_inner'>
                    <div className='widgets_icon'>
                      <Category />
                    </div>
                    <div>
                      <h2>{dashboard?.data?.category}</h2>
                      <p>Category</p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card className='widgets_card'>
                  <div className='widgets_card_inner'>
                    <div className='widgets_icon'>
                      <Employee />
                    </div>
                    <div>
                      <h2>{dashboard?.data?.staff}</h2>
                      <p>Employee</p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card className='live_stock_wrapper' title="Low Stock Products">
                  <List
                    itemLayout="horizontal"
                    dataSource={productData}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.name}
                          description={item.sku}
                        />
                        <div className='live_stock_count'>
                          <Button type="primary" shape="circle">{item.qty ? item.qty : 0}</Button>
                        </div>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Card id='myCard' className='live_stock_wrapper job_card_wrapper' style={{ position: 'relative' }} title="Job Details"
              extra={
                <div className='form_control'>
                  <Select
                    showSearch
                    placeholder="Last one month"
                    defaultValue="Last one month"
                    optionFilterProp="children"
                    onChange={(value) => setMonth(value)}
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                  >
                    <Option value="1">Last one month</Option>
                    <Option value="3">Last three months</Option>
                    <Option value="6">Last six months</Option>
                  </Select>
                </div>}>

              <Tabs defaultActiveKey="1" onChange={(key) => setJobStatus(key)} >
                <Tabs.TabPane style={{ height: loaderHeight }} tab={<>Pending <span className='badge_count'>{dashboardData?.data?.pendingJobCard}</span> </>} key="pending">
                  {addLoader ? <Spin className='custom-list-spin' size='large' spinning={addLoader}></Spin> : <List
                    itemLayout="horizontal"
                    dataSource={jobcardData}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.jobCardNo}
                        />
                        <List.Item.Meta
                          title={moment(item.create_date).format("DD-MM-YYYY hh:mm:A")}
                        />
                        <div className='live_stock_count'>
                          {item.status === "pending" ? <span className='pending_task live_stock_count1'>Pending</span> : <></>}
                        </div>
                      </List.Item>
                    )}
                  />}
                </Tabs.TabPane>
                <Tabs.TabPane style={{ height: loaderHeight }} tab={<>In progress <span className='badge_count'>{dashboardData?.data?.inprocessJobCard}</span> </>} key="inprocess">
                  {addLoader ? <Spin className='custom-list-spin' size='large' spinning={addLoader}></Spin> : <List
                    itemLayout="horizontal"
                    dataSource={jobcardData}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.jobCardNo}
                        />
                        <List.Item.Meta
                          title={moment(item.start_date).format("DD-MM-YYYY hh:mm:A")}
                        />
                        <div className='live_stock_count'>
                          {item.status === "inprocess" ? <span className='inprocess_task live_stock_count1'>In progress</span> : <></>}
                        </div>
                      </List.Item>
                    )}
                  />}
                </Tabs.TabPane>
                <Tabs.TabPane style={{ height: loaderHeight }} tab={<>Completed <span className='badge_count'>{dashboardData?.data?.completedJobCard}</span> </>} key="completed">
                  {addLoader ? <Spin className='custom-list-spin' size='large' spinning={addLoader}></Spin> : <List
                    itemLayout="horizontal"
                    dataSource={jobcardData}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.jobCardNo}
                        />
                         <List.Item.Meta
                          title={moment(item.end_date).format("DD-MM-YYYY hh:mm:A")}
                        />
                        <div className='live_stock_count'>
                          {item.status === "completed" ? <span className='completed_task live_stock_count1'>Completed</span> : <></>}
                        </div>
                      </List.Item>
                    )}
                  />}
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>

    </>
  )
}

export default DashbordContent


