import React from "react";
import { Button, Form, Input, message } from "antd";
import "./Login.css";
import { useDispatch } from "react-redux";
import { loginslice } from "../../redux/state/loginSlice";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

export const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(process.env.REACT_APP_API_URL, "My RUL..");

    const handleLogiin = (values) => {
        try {
            values = { ...values };
            dispatch(loginslice(values)).then((response) => {
                if (response.payload.success) {
                    const admin = response.payload.data;
                    const token = response.payload.data ? response.payload.data.token : {};
                    localStorage.setItem("admin", JSON.stringify(admin));
                    localStorage.setItem("token", JSON.stringify(token));
                    navigate("/", { replace: true });
                } else {
                    message.error(response.payload.message);
                }
            });
        } catch (error) {
            message.error(error, "Login error");
        }
    };

    return (
        <>
            <div className="login_background">
                <Form layout="vertical" name="login-form" onFinish={handleLogiin} className="login_form">
                    <div className="logo_login">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="Login_logo">
                        <label className="form_lable">Login</label>
                    </div>
                    <div className="container login_form_content">
                        <Form.Item
                            className="form_item form_control"
                            name="email"
                            label={
                                <div className="Input_div">
                                    <span className="input_lable">Email</span>
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter email",
                                },
                                {
                                    type: "email",
                                    message: "Please enter a valid email",
                                },
                            ]}
                        >
                            <Input className="input_field" />
                        </Form.Item>
                        <Form.Item
                            className="form_item"
                            name="password"
                            label={
                                <div className="Input_div">
                                    <span className="input_lable">Password</span>
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter password",
                                },
                            ]}
                        >
                            <Input.Password type="password" className="input_field" />
                        </Form.Item>
                        <Form.Item className="forgot_text">
                            {/* <p className="forgot_text" style={{ color: "#041E42", cursor: "pointer" }} >
                Forgot Password?
              </p> */}
                        </Form.Item>
                        <Form.Item>
                            <Button type="submit" className="login_btn" htmlType="submit" block>
                                Login
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default LoginForm;
