import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import bulkdataService from '../api/bulkuploadApi'

const initialState = {
    filedata: [],
    isLoading: false,
    error: null
}

export const postTemplateslice = createAsyncThunk('template/retrive', async ({ formData, actioncheck }) => {
    try {
        const response = await bulkdataService.postbulkfiledata(formData, actioncheck)
        return response?.data
    } catch (error) {
        console.log(error)
    }
})
export const postNewTemplateslice = createAsyncThunk('Newtemplate/retrive', async (data) => {
    try {
        const response = await bulkdataService.postNewbulkfiledata(data)
        return response?.data
    } catch (error) {
        console.log(error)
    }
})

export const postBulkImageslice = createAsyncThunk('template/retrive', async (formData) => {
    try {
        const response = await bulkdataService.postbulkImagefiledata(formData)
        return response?.data
    } catch (error) {
        console.log(error)
    }
})

export const postBulkTemplateslice = createAsyncThunk('template/retrive', async (formData) => {
    try {
        const response = await bulkdataService.postbulkTemplatedata(formData)
        return response?.data
    } catch (error) {
        console.log(error)
    }
})

const bulkreaderSlice = createSlice({
    name: 'filedata',
    initialState,
    reducers: {},
    extraReducers: {
        [postTemplateslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [postTemplateslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        },
        [postTemplateslice.rejected]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        },
        [postNewTemplateslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [postNewTemplateslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        },
        [postNewTemplateslice.rejected]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        }
        ,
        [postBulkImageslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [postBulkImageslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        },
        [postBulkImageslice.rejected]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        }
        ,
        [postBulkTemplateslice.pending]: (state, action) => {
            state.isLoading = true
        },
        [postBulkTemplateslice.fulfilled]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        },
        [postBulkTemplateslice.rejected]: (state, action) => {
            state.isLoading = false
            state.filedata = [action.payload]
        }
    }
});

export default bulkreaderSlice.reducer