import React, { useState, useEffect } from 'react'
import './job.css'
import { Button, Select, Spin, Form, message, Row, Col, Radio } from 'antd';
import Input from '../common/Index'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getProductDropSlice, getTemplateDropSlice } from '../../redux/state/dropdownSlice';
import { addJobslice, getJobslice } from '../../redux/state/jobSlice';
import {  PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { isNumber } from 'lodash';
import { getSingleTemplateSlice } from '../../redux/state/templateSlice';
// import ColumnGroup from 'antd/lib/table/ColumnGroup';


const { Option } = Select;



const AddJob = ({ setAddModel }) => {
  const [form] = Form.useForm();
  const [inputNumberValue, setInputNumberValue] = useState('')
  const [productData, setProductData] = useState([])
  const [templateData, setTemplateData] = useState([])
  const [editProductRecord, setEditProductRecord] = useState([])
  const [editOldData, setEditOldData] = useState([])
  const [templateId, setTemplateId] = useState([])
  const [data, setData] = useState([]);
  const [templateShow, setTemplateShow] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams();



  useEffect(() => {
    dispatch(getJobslice())
    dispatch(getProductDropSlice())
    dispatch(getSingleTemplateSlice(templateId))
    dispatch(getTemplateDropSlice())
  }, [dispatch, templateId]);


  const { productdropdowns, templatedropdowns } = useSelector(data1 => data1.sitedropdowns)
  const { templatesItems, isLoading: addLoader } = useSelector(state => state.templates)
  useEffect(() => {
    if (templatesItems[0]?.success && templatesItems[0]?.data) {
      setEditProductRecord(templatesItems[0]?.data);
    }
  }, [templatesItems])

  useEffect(() => {
    if (productdropdowns && productdropdowns.data) {
      const drpdown = productdropdowns.data.map((e) => {
        return { value: e._id, label: e.name }
      })
      setProductData(drpdown);
    }
    if (templatedropdowns && templatedropdowns.data) {
      const tempdown = templatedropdowns.data.map((e) => {
        return { ...e, key: e._id }
      })
      setTemplateData(tempdown);
    }

  }, [productdropdowns, templatedropdowns,])


  useEffect(() => {
    if (editProductRecord && editProductRecord?.templateItem?.length > 0) {
      const oldjobcardProductItem = editProductRecord?.templateItem.map((item, index) => {
        return {
          product_name: item.productId.name ? item.productId._id : "",
          comment: item.comment ? item.comment : "",
          quantity: item.qty ? item.qty : "1"
        }
      })
      setEditOldData(oldjobcardProductItem)
      if (templateShow === 0) {
        form.setFieldsValue({ cardItem: [] })
      } else {
        form.setFieldsValue({ cardItem: oldjobcardProductItem })
      }
    }
  }, [editProductRecord, templateShow])


  const handleResetSite = async () => {
    try {
      setAddModel(false)
      setData([])
      form.resetFields()

    } catch (error) {
      console.error(error)
    }
  }


  // ADD JOBDATA 
  const handleAddJob = (values) => {
    const { jobCardNo, cardItem } = values;
    if (!Array.isArray(cardItem) || cardItem == undefined || cardItem.length === 0) {
      message.error("Add at least one product")
      return
    }
    else if (values.jobCardNo.trim().length === 0) {
      message.error("Chassis number required")
      return
    }
    try {
      dispatch(addJobslice(values))
        .then((response) => {
          if (response.payload.success) {
            form.resetFields()
            setData([])
            setAddModel(false)
            dispatch(getJobslice())
            navigate("/jobcard", { replace: true })
            message.success("Jobcard added successfully")
          } else {
            // message.error("error", response.payload)
            message.error(response.payload.message)
          }
        }).catch((err) => {
          console.error(err)
        })
    }
    catch (err) {
      message.error(err, "add Location error")
    }

  }

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (isNumber(inputValue)) {
      setInputNumberValue(inputValue)
    }
  };

  const onKeyPress = (e) => {
    let validCharCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    if (!validCharCodes.includes(e.charCode)) {
      e.preventDefault();
    }
  }

  return (
    <>
      {/* Job Discription add */}
      <Spin size='large' spinning={addLoader}>
        <Form layout='vertical ' form={form} name="dynamic_form_nest_item" initialValues={editProductRecord} onFinish={handleAddJob} autoComplete="off">
          <Form.Item className="form_control" label={
            <>
              <span className="product_lable">Use template </span>
            </>
          }>
            <Radio.Group className="p-1" defaultValue={0} onChange={(e) => {
              setTemplateShow(e.target.value);
            }}>
              <Radio className="form-check-label" name="before_purchase_type_change" value={0}>
                No
              </Radio>
              <Radio className="form-check-label" name="before_purchase_type_change" value={1}>
                Yes
              </Radio>
            </Radio.Group>
          </Form.Item>
          {(templateShow == 0) ? "" :
            <div className="col-md-6">
              <div className="mb-3 product_lable">
                <Form.Item
                  label="Select template"
                  name="depreciation_method"
                  rules={[
                    {
                      required: true,
                      message: "Please select template!",
                    },
                  ]}
                >
                  <Select className="form_control" showSearch placeholder="Templates" onChange={(e) => {
                    setTemplateId(e);
                  }}>
                    {templateData.map((template, i) => {
                      const { _id, templateName } = template;
                      return (
                        <Option key={_id} value={_id} >
                          {templateName}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
          }
          <Form.Item
            className='form_control'
            name="jobCardNo"
            label={
              <>
                <span className="product_lable">Chassis number : </span>
              </>
            }
            rules={[
              {
                required: true,
                message: 'Chassis number is required',
              },
            ]}
          >
            <Input maxLength={80} inputtype="alphanumnospace" />

          </Form.Item>
          <div className="job_product_data">
            <Form.List name="cardItem">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (

                    <>
                      {
                        form.getFieldValue('cardItem').map((element, index) => {
                          if (element !== undefined && element.product_name !== undefined) {
                          }
                        })
                      }
                      <Row gutter={10} className="add_product" >
                        <Col span={10}>
                          <Form.Item
                            className='form_control'
                            {...field}
                            name={[field.name, 'product_name']}
                            placeholder="Select Product Name"
                            rules={[
                              {
                                required: true,
                                message: 'Product name is required',
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Product Name"
                              showSearch
                              options={productData}
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}

                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} >
                          <Form.Item
                            className='form_control'
                            {...field}
                            name={[field.name, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: 'Quantity is required',
                              }
                            ]}
                          >
                            <Input placeholder="Quantity" maxLength={6} inputtype="number" handlechange={handleChange} onKeyPress={onKeyPress} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            className='form_control'
                            {...field}
                            // label="Price"
                            name={[field.name, 'comment']}

                          >
                            <Input placeholder='Comment' />
                          </Form.Item>
                        </Col>
                        <Col span={2} className="remove_job">
                          <DeleteOutlined onClick={() => remove(field.name)} />

                        </Col>
                      </Row>
                    </>
                  ))}

                  <Form.Item>
                    <Button type="dashed" className='add_new_product' onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Products
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          <Form.Item>
            <div className="third_row">
              <Button className='Cancel_btn' size="large" onClick={() => handleResetSite()}>
                Cancel
              </Button>
              <Button className='add_site_btn' size="large" htmlType='submit'>
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  )
}

export default AddJob
