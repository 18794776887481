import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../../assets/icons/EditIcon";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import {
  Table,
  Button,
  Spin,
  message,
  Popconfirm,
  Modal,
  Row,
  Col,
  Input,
  Form,
  Checkbox,
  Space,
} from "antd";
import {
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddTemplate from "./addTemplate";
import {
  deleteTemplateSlice,
  getTemplateByIdslice,
  getTemplateslice,
} from "../../redux/state/templateSlice";
import { useDispatch, useSelector } from "react-redux";
import EditTemplate from "./editTemplate";
import Highlighter from "react-highlight-words";
import BulkUpload from "../Products/bulkupload";
import { Resizable, ResizableBox } from "react-resizable";
import Draggable from "react-draggable";

const Template = () => {
  const [addModal, setAddModel] = useState(false);
  const [editModal, setEditModel] = useState(false);
  const [viewModal, setViewModel] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [tempProductData, setTempProductData] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [editRecord, setEditRecord] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [bulkView, setBulkView] = useState(false);
  const [allData, setAllData] = useState([]);
  const [checkTemp, setCheckTemp] = useState(false);
  const [myBulkForm] = Form.useForm();
  const [next, setNext] = useState(false);
  const [size, setSize] = useState({ width: 800, height: 400 });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const draggleRef = useRef(null);

  useEffect(() => {
    dispatch(getTemplateslice());
  }, [dispatch]);
  const { templates, isLoading: addLoader } = useSelector(
    (state) => state.templates
  );

  useEffect(() => {
    if (Array.isArray(templates?.data)) {
      setData(templates?.data);
    }
  }, [templates]);

  //resize modal
  const handleResize = (event, { size }) => {
    setSize(size);
  };
  //dragable modal
  const { clientWidth, clientHeight } = window.document.documentElement;

  const onStart = (_event, uiData) => {
    console.log("clientHeight,clientWidth :>> ", clientHeight, clientWidth);
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x + 10),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y + 20),
    });
  };

  const fullWidthAdjustable = async () => {
    if (toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      dragableElement.classList.add("topleftmodal");
      setSize({ width: clientWidth - 100, height: clientHeight - 130 });
    }
    setToggle(false);
  };
  const minWidthAdjustable = async () => {
    setToggle(true);
    if (!toggle) {
      const dragableElement = document.querySelector(".react-draggable");
      console.log("dragableElement :>> ", dragableElement);
      dragableElement.classList.remove("topleftmodal");
      setSize({ width: 800, height: 400 });
    }
  };

  //  template delete
  const handleDeleteTemplateItem = (id) => {
    try {
      dispatch(deleteTemplateSlice(id)).then((response) => {
        if (response.payload.success == true) {
          dispatch(getTemplateslice());
          message.success("Template deleted successfully");
        } else {
          message.error(response.payload.message);
        }
      });
    } catch (error) {
      message.error(error, "error delete time");
    }
  };

  const handleCancel = () => {
    setViewModel(false);
    setSize({ width: 800, height: 400 });
    setToggle("true")
  };
  const ViewTemplateItem = (id) => {
    try {
      setViewModel(true);
      dispatch(getTemplateByIdslice(id)).then((response) => {
        if (response.payload.success && response.payload.data) {
          const productItems = response.payload.data?.templateItem?.map((e) => {
            return { ...e, key: e._id };
          });
          setTemplateData(response.payload.data);
          setTempProductData(productItems);
        } else {
          message.error(response.payload.message);
        }
      });
    } catch (error) {
      console.error(error, "get JobCardItem");
    }
  };

  const EditTemplateItem = (record, id) => {
    setEditRecord(record);
    setEditModel(true);
  };

  // SEARCHING
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    console.log("first");
    setSearchText("");
    clearFilters();
    close();
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              confirm &&
              handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleResetSiteBulk = () => {
    setNext(false);
    setAllData([]);
    myBulkForm.resetFields();
    setBulkView(false);
  };

  const handelUploadTemp = () => {
    setBulkView(true);
    setCheckTemp(true);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (_, record, id) => {
        return (
          <>
            {/* {id + 1} */}
            {(page - 1) * 10 + (id + 1)}
          </>
        );
      },
    },
    {
      title: "Template Name",
      dataIndex: "templateName",
      ...getColumnSearchProps("templateName", "Template Name"),
      sorter: (a, b) => a.templateName.localeCompare(b.templateName),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const text = "Are you sure to delete this Item ?";
        return (
          <>
            <span>
              <Button
                className="btn_view mr_1"
                onClick={() => ViewTemplateItem(record._id)}
                icon={<EyeOutlined />}
              />

              <Button
                className="btn_edit mr_1"
                onClick={() => EditTemplateItem(record, record._id)}
                icon={<EditIcon />}
              />

              <Popconfirm
                placement="topRight"
                title={text}
                onConfirm={() => handleDeleteTemplateItem(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="btn_delete" icon={<DeleteIcon />} />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];

  const Productcolumns = [
    {
      title: "Product name",
      dataIndex: "name",
      render: (_, record) => {
        return <>{record?.productId?.name}</>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "age",
      render: (_, record) => {
        return <>{record.qty ? record.qty : "1"}</>;
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "address",
      render: (_, record) => {
        return <>{record.comment ? record.comment : "-"}</>;
      },
    },
    // {
    //     title: 'status',
    //     dataIndex: '',
    //     key: 'status',
    //     render: (_, record) => {
    //         return (
    //             <>
    //                 {/* <Checkbox checked= {record.outItemStatus ? record.outItemStatus : false} ></Checkbox> */}
    //                 {record.outItemStatus == true ? <span className='completed_task'>Completed</span> :
    //                     <span className='inprocess_task'>Inprocess</span>}
    //             </>
    //         )
    //     }
    // }
  ];

  return (
    <>
      <Spin size="large" spinning={addLoader}>
        <div className="main_wrapper">
          <div className="site_header">
            <label className="content_heading">
              <h2>Template Details</h2>
            </label>
            <div>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    className="bulk_btn"
                    size="large"
                    onClick={() => handelUploadTemp()}
                  >
                    <span>
                      <PlusOutlined />
                    </span>{" "}
                    Import
                  </Button>
                </Space>
              </a>
              <Button
                className="add_btn"
                size="large"
                onClick={() => setAddModel(true)}
              >
                {/* <Button className='add_btn' size="large" onClick={() => navigate("/jobcard/add-job", { replace: true })} > */}
                <span>
                  <PlusOutlined />
                </span>{" "}
                Add New
              </Button>
            </div>
          </div>

          {/* jobcard Table  */}
          <Table
            sticky={true}
            columns={columns}
            bordered
            dataSource={data}
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            size="middle"
            className="tabel_height main_table"
          />
        </div>

        {/* EDIT Modal  */}

        <div className="demo">
          <Modal
            title={
              <div className="title_div">
                <div
                  style={{
                    width: "96%",
                    cursor: "move",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onMouseOver={() => {
                    if (disabled) {
                      setDisabled(false);
                    }
                  }}
                  onMouseOut={() => {
                    setDisabled(true);
                  }}
                >
                  Edit template
                </div>
                {toggle ? (
                  <div
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      width: "25px",
                    }}
                    onClick={fullWidthAdjustable}
                  >
                    <FullscreenOutlined />
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      width: "25px",
                    }}
                    onClick={minWidthAdjustable}
                  >
                    <FullscreenExitOutlined />
                  </div>
                )}
              </div>
            }
            footer={null}
            open={editModal}
            width={size.width + 50}
            min-width={800}
            mask={false}
            maskClosable={false}
            onCancel={() => {
              setEditModel(false);
              setSize({ width: 800, height: 400 });
              setToggle("true");
            }}
            modalRender={(modal) => (
              <Draggable
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>
            )}
            className="job_product_data"
          >
            <ResizableBox
              width={size.width}
              height={size.height}
              onResize={handleResize}
              minConstraints={[900, 400]}
              maxConstraints={[clientWidth - 100, clientHeight - 50]}
            >
              <EditTemplate
                setEditModel={setEditModel}
                editRecord={editRecord}
              />
            </ResizableBox>
          </Modal>
        </div>

        {/* Add modal  */}
        <Modal
          title={
            <div className="title_div">
              <div
                style={{
                  width: "96%",
                  cursor: "move",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false);
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true);
                }}
              >
                Add new template
              </div>
              {toggle ? (
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    width: "25px",
                  }}
                  onClick={fullWidthAdjustable}
                >
                  <FullscreenOutlined />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    width: "25px",
                  }}
                  onClick={minWidthAdjustable}
                >
                  <FullscreenExitOutlined />
                </div>
              )}
            </div>
          }
          footer={null}
          open={addModal}
          width={size.width + 50}
          onCancel={() => {
            setAddModel(false);
            setSize({ width: 800, height: 400 });
            setToggle("true");
          }}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          className="job_product_data"
        >
          <ResizableBox
            width={size.width}
            height={size.height}
            onResize={handleResize}
            minConstraints={[900, 400]}
            maxConstraints={[1800, 750]}
          >
            <AddTemplate setAddModel={setAddModel} addModal={addModal} />
          </ResizableBox>
        </Modal>
      </Spin>
      {/* UPLOAD MODAL  */}
      <Modal
        title="Product import"
        footer={null}
        open={bulkView}
        maskClosable={false}
        width={850}
        onCancel={() => handleResetSiteBulk()}
      >
        <BulkUpload
          setBulkView={setBulkView}
          myBulkForm={myBulkForm}
          setAllData={setAllData}
          next={next}
          setNext={setNext}
          allData={allData}
          checkTemp={checkTemp}
        />
      </Modal>
      {/* View Model popup  */}
      <Modal
        title={
          <div className="title_div">
            <div
              style={{
                width: "96%",
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              Template details
            </div>
            {toggle ? (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={fullWidthAdjustable}
              >
                <FullscreenOutlined />
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  width: "25px",
                }}
                onClick={minWidthAdjustable}
              >
                <FullscreenExitOutlined />
              </div>
            )}
          </div>
        }
        open={viewModal}
        footer={null}
        width={size.width + 50}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        className="job_product_data"
      >
        <ResizableBox
          width={size.width}
          height={size.height}
          onResize={handleResize}
          minConstraints={[900, 400]}
          maxConstraints={[clientWidth - 100, clientHeight - 50]}
        >
          <div className="model_lable_1 ">
            <Row gutter={12}>
              <Col span={16} className="product_lable_view">
                <Form.Item
                  className="form_control"
                  label={<span className="product_lable_1">Template name</span>}
                >
                  <p className="site_input_group_1">
                    {templateData.templateName}
                  </p>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="product_data">
            <Table
              dataSource={tempProductData}
              bordered
              sticky
              columns={Productcolumns}
              pagination={false}
              className="job_product_data"
            />
          </div>
        </ResizableBox>
      </Modal>
    </>
  );
};

export default Template;
