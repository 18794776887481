import React from 'react'
import {  Layout } from 'antd';
import './index.css'
import { Outlet } from 'react-router-dom';
// import { getLowProductslice } from '../../../redux/state/lowProductSlice';
const { Content } = Layout;

const Contents = ({ style }) => {

    return (
        <Content
            className="site-layout" style={style}
        >
            <div>
                <Outlet />
            </div>
        </Content>
    )
}

Content.defaultProps = {
    style: {
        padding: '0 25px',
        marginTop: 25,
    }
}

export default Contents